// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router'
import Chat from '../views/Chat.vue'
import ImageChat from '../views/Chat/ImageChat.vue'
// import AiIndex from '../views/AiIndex.vue'
import ChatModule from '../views/ChatModule.vue'
import EnterpriseServices from '../views/EnterpriseServices.vue'
import WssScreen from '../views/LargeScreen/WssScreen.vue'
import VipBuy from '../views/User/VipBuy.vue'
import VipPayStatus from '../views/User/VipPayStatus.vue'
import UserAgreement from '../views/User/UserAgreement.vue'
import UserPrivate from '../views/User/UserPrivate.vue'
import VipOrder from '../views/User/VipOrder.vue'
import Elevator from '../views/Elevator.vue'
import TravelSuggest from '../views/KouZi/TravelSuggest.vue'
import EnglishLearning from '../views/KouZi/EnglishLearning.vue'
import NewsRecent from '../views/KouZi/NewsRecent.vue'
import Industry from '../views/KouZi/Industry.vue'
import LabReport from '../views/KouZi/LabReport.vue'
import MainIndex from '../views/MainIndex.vue'
import ServiceCenter from '../views/ServiceCenter.vue'
import test from '../views/test.vue'

import AiSet from '../views/AiSet.vue'
import BusinessSegments from "@/views/BusinessSegments.vue";



const routes = [
  {
    path: '/',
    name: 'MainIndex',
    component: MainIndex,
    meta: {
      title: '壁虎科技'
    }
  },
  {
    path: '/serviceCenter',
    name: 'ServiceCenter',
    component: ServiceCenter,
    meta: {
      title: '业务展示中心--壁虎科技'
    }
  },
  {
    path: '/test',
    name: 'test',
    component: test,
    meta: {
      title: '壁虎科技'
    }
  },

  {
    path: '/chat',
    name: 'Chat',
    component: Chat,
    meta: {
      title: '壁虎科技'
    }
  },
  {
    path: '/imgChat',
    name: 'ImgChat',
    component: ImageChat,
    meta: {
      title: '壁虎科技'
    }
  },

  {
    path: '/aiset',
    name: 'AiSet',
    component: AiSet,
    meta: {
      title: '壁虎科技'
    }
  },
  {
    path: '/chatm',
    name: 'ChatModule',
    component: ChatModule,
    meta: {
      title: '壁虎科技'
    }
  },
  {
    path: '/es',
    name: 'EnterpriseServices',
    component: EnterpriseServices,
    meta: {
      title: '壁虎科技'
    }
  },
  {
    path: '/bs',
    name: 'BusinessSegments',
    component: BusinessSegments,
    meta: {
      title: '业务板块'
    }
  },
  {
    path: '/wssscreen',
    name: 'WssScreen',
    component: WssScreen,
    meta: {
      title: '壁虎科技'
    }
  },


  {
    path: '/vip-pay',
    name: 'VipBuy',
    component: VipBuy,
    meta: {
      title: '开通会员'
    }
  },
  {
    path: '/vip-pay-status',
    name: 'VipPayStatus',
    component: VipPayStatus,
    meta: {
      title: '会员开通成功'
    }
  },
  {
    path: '/agreement',
    name: 'UserAgreement',
    component: UserAgreement,
    meta: {
      title: '用户协议'
    }
  },
  {
    path: '/private',
    name: 'UserPrivate',
    component: UserPrivate,
    meta: {
      title: '隐私政策'
    }
  },
  {
    path: '/vip-order',
    name: 'VipOrder',
    component: VipOrder,
    meta: {
      title: '订单信息'
    }
  },
  {
    path: '/elevator',
    name: 'Elevator',
    component: Elevator,
    meta: {
      title: '电梯板块'
    }
  },

  {
    path: '/travel',
    name: 'TravelSuggest',
    component: TravelSuggest,
    meta: {
      title: '旅行推荐官'
    }
  },
  {
    path: '/english',
    name: 'EnglishLearning',
    component: EnglishLearning,
    meta: {
      title: '英语训练师'
    }
  },
  {
    path: '/news',
    name: 'NewsRecent',
    component: NewsRecent,
    meta: {
      title: '时事热点眼'
    }
  },
  {
    path: '/industry',
    name: 'Industry',
    component: Industry,
    meta: {
      title: '行业分析师'
    }
  },
  {
    path: '/labReport',
    name: 'LabReport',
    component: LabReport,
    meta: {
      title: '实验报告生成器'
    }
  },

















]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
