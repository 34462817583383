<!-- src/views/Home.vue -->
<template>
  <div style="width: 100%">
    <div style="height:86vh;">
      <div class="flex flex-align-center fs-12 space-between" style="background-color:#f7f7f8;color:#3d3f3f;height:24px;padding:6px 16px;">
        <router-link to="/">
          <div style="color:#3d3f3f;text-decoration: none;">首页</div>
        </router-link>
        <div style='font-size:18px;font-weight: 400;'>行业分析师</div>
      </div>
      <div class="chat-box" ref="msgContainerRef">
        <div v-show="messages.length<=0" class="flex flex-column flex-align-center justify-content-center" style="height:70vh;">
          <div style="width:40%"><img src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/3771774579254700_1741329057777154739.jpeg" alt="" style="width:50px;height:50px;border-radius: 8px;"></div>
          <div><h2>行业分析师</h2></div>
          <div style="width:40%;text-align: left;background-color: #f4f4f4;padding:6px 10px;line-height: 2;border-radius: 8px;" >
            <span>聚焦行业速递，了解行业动态，欢迎使用行业分析师，通过 4P 分析法，波特五力模型快速帮您了解一个行业，可以选择下面的选项快速开始：</span>
          </div>
          <div class="chat-box-item" style="margin-top:20px;"><div @click="setUserMessage('电竞行业')">电竞行业</div></div>
          <div class="chat-box-item" style="margin-top:10px;"><div @click="setUserMessage('外卖行业')">外卖行业</div></div>
          <div class="chat-box-item" style="margin-top:10px;"><div @click="setUserMessage('无人机行业')">无人机行业</div></div>
        </div>
        <div class="messages" v-show="messages.length>0">
          <div v-for="(message, index) in messages" :key="index" :class="message.role == 'user' ? 'user-info' : 'assistant-info'"
               style="display:flex;margin:28px 0;">
            <div v-if="message.role == 'user'" style="display: flex;flex-direction: column;align-items: flex-end;">
              <div class="chat-extra" v-if="message.extra">
                <el-icon style="color:#f57373;"><Document /></el-icon>
                <span class="ml-5">{{ message.extra }}</span>
              </div>
              <div class="chat-content" >{{ message.content }}</div>
            </div>
            <div class="chat-content-assistant" v-if="message.role == 'assistant'">
              <el-image class="ai-robot-img"
                        :src="aiRobotAvatar"  />
              <div style="width:700px;">
                <!--                    <div style="font-size:14px;">{{message.content}}</div>-->
                <v-md-preview :text="message.content" style="padding:0px;"></v-md-preview>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-affix position="bottom" :offset="40">
      <div style="width:760px;margin:0 auto;background-color:#fff;">
        <div class="search-input" :class="{ focused: isFocused }">
          <div class="flex flex-center">
            <input v-model="newMessage" @keyup.enter="sendMessage" type="text" class="no-border" style="padding:10px;width:740px;font-size:14px;" placeholder="请输入问题" @focus="handleFocus" @blur="handleBlur">
            <div class="flex flex-center ml-10">
              <el-image v-if="scriptRun==false" class="cursor-pointer" style="width: 32px; height: 32px"
                        src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/new-arrow-circle-up.png" @click="sendMessage"/>
              <el-image v-if="scriptRun==true" class="cursor-pointer" style="width: 32px; height: 32px"
                        src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/new-one.png" @click="stopGetMsg"/>

            </div>
          </div>
        </div>
      </div>
    </el-affix>
  </div>
  <Login :loginFlag="loginFlag" @update-prop="updateLoginFlag" />
</template>

<script>
import {ref, nextTick, reactive, FatalError, onMounted} from 'vue';
import { fetchEventSource } from '@microsoft/fetch-event-source';



import { ElMessage } from 'element-plus'
import {apiTravelCreateConversation} from "@/api/chat/elevator";
import {Document} from "@element-plus/icons-vue";
import Login from "../../components/common/Login.vue";


export default {
  name: 'TravelSuggest',
  components:{
    Login,
    Document
  },
  setup() {


    const internetFlag = ref(false);
    const messages = ref([]);
    const newMessage = ref('');
    const msgContainerRef = ref(null);
    const fileLoading = ref(true);
    const fileUploadInput = ref(null);
    const fileUploadName = ref("");

    const conversationId = ref("");
    const contentRunning = ref(false)
    const scriptRun = ref(false)
    const uuid = ref("")
    onMounted(() => {
      initOperate();
    });

    const initOperate = () => {
      apiTravelCreateConversation().then(res => {
        if (res.data.results.code === 0) {
          conversationId.value = res.data.results.data.id;
          uuid.value = localStorage.getItem("uuid")
        }
      })
    }

    const loginFlag = ref(false);
    const showLogin = () => {
      loginFlag.value = true;
    }
    const updateLoginFlag = (newValue, isLogin) => {
      loginFlag.value = newValue;
      if (parseInt(isLogin) === 1) {
        initOperate()
      }
    }

    const aiRobotAvatar = ref('https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png');
    const setUserMessage = (msg) => {
      aiOperate(msg)
    }
    const tongyiAi = async () => {
      if (newMessage.value != '') {
        let new_value = {
          "role": "user",
          "content": newMessage.value,
          "content_type": "text",
          "type": "question",
        }
        messages.value.push(new_value);
        setElevatorRecord(newMessage.value, 'user');//存入数据库
      }

      const requestMessage = JSON.parse(JSON.stringify(messages.value));
      nextTick(()=>{
        scrollBottom();
      });
      newMessage.value = '';

      let tmpContent = reactive({
        "role": "assistant",
        "content": "⚫",
        "type": "answer",
        "content_type": "text",
      });
      messages.value.push(tmpContent);
      scriptRun.value = true;
      const ctrl = new AbortController();

      fetchEventSource('/aiApi/tongyanTravel', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "messages": requestMessage,
          "conversation_id": conversationId.value,
          "uuid":uuid.value,
          "engine_type": "industry"
        }),
        signal: ctrl.signal,
        onmessage(msg) {

          if (msg.event == "conversation.message.delta") {
            contentRunning.value = true; //进行中
          }

          if (msg.event == "conversation.message.completed") {
            contentRunning.value = false;
          }


          if (msg.event == 'done' || !scriptRun.value) {
            tmpContent.content = tmpContent.content.replace(/⚫$/, "");
            contentRunning.value = false;
            scriptRun.value = false;
            ctrl.abort()

            if (msg.event == 'done') {
              // setElevatorRecord( tmpContent.content, 'assistant');
            }

            return;
          }

          if (msg.event == '' && contentRunning.value) {
            tmpContent.content = tmpContent.content.replace(/⚫$/, "");
            try {
              const data = JSON.parse(msg.data);
              if (data.content != undefined && data.type=="answer") {
                tmpContent.content +=  data.content+'⚫';
              }

              nextTick(()=>{
                scrollBottom();
              });
            } catch (err) {
              console.log(err)
              throw err;
            }
          }

        }
      });
    }

    const stopGetMsg = () => {
      if (scriptRun.value) {
        scriptRun.value = false;
      }
    }


    const aiOperate = (askContent = '') => {
      if (parseInt(localStorage.getItem('is_login')) !== 1) {
        ElMessage({
          message: '请先登录后操作！',
          type: 'warning',
        })
        showLogin();
        return;
      }

      if (scriptRun.value) {
        return;
      }
      //判断用户等级 试用用户每天只可以问三次
      // if (parseInt(localStorage.getItem('user_level')) === 0) {
      //   let timesKey = 'user_test_times';
      //   let todayDateStr = todayDate();
      //   let user_test_times = JSON.parse(localStorage.getItem(timesKey));
      //   if (user_test_times==null ||(user_test_times.date_info != undefined && user_test_times.date_info != todayDate)) {
      //     user_test_times = {
      //       times: 0,
      //       date_into: todayDateStr
      //     }
      //   }
      //
      //   if (parseInt(user_test_times.times) >= 3) {
      //     ElMessage({
      //       message: '试用用户每天最多可以使用3次！',
      //       type: 'warning',
      //     })
      //     return;
      //   }
      //
      //   user_test_times.times = user_test_times.times + 1;
      //   localStorage.setItem(timesKey, JSON.stringify(user_test_times));
      // }

      if (askContent != '') {
        newMessage.value = askContent;



        tongyiAi();

      }
    }

    const sendMessage =  () => {
      let askContent = newMessage.value.trim();
      if (askContent !== '') {
        aiOperate(askContent)
      }
    }

    const setElevatorRecord = ( content, talkType) => {
      // let param = {
      //   content: content,
      //   talk_type: talkType,
      // }
      // apiSetElevatorRecord(param).then(res => {
      //   if (res.err_no == 0) {
      //     console.log(res.data.results);
      //   }
      // })
    }

    const isFocused = ref(false);

    const handleFocus = () => {
      isFocused.value = true;
    }
    const handleBlur = () =>{
      isFocused.value = false;
    }

    const scrollBottom = ()=>{
      msgContainerRef.value.scrollTop = msgContainerRef.value.scrollHeight;
    }

    // 点击按钮时触发文件输入框
    const triggerFileUploadInput = () => {
      fileUploadInput.value.click();
    };

    return {
      messages,
      newMessage,
      msgContainerRef,
      sendMessage,
      aiRobotAvatar,

      internetFlag,

      isFocused,
      fileUploadInput,
      fileUploadName,
      fileLoading,
      handleFocus,
      handleBlur,
      triggerFileUploadInput,
      stopGetMsg,
      setUserMessage,
      scriptRun,
      loginFlag,
      updateLoginFlag
    };
  }
}
</script>

<style scoped>
.chat-list {
  background-color:#f9f9f9;
}
.chat-box {
  width: 100%;
  height: 80vh;
  overflow-y:auto;
}

.messages {
  width: 800px;
  margin: 0 auto;
}

.user-info {
  justify-content: flex-end;
}

.assistant-info {
  justify-content: flex-start;
}
.chat-content {
  background-color: #f4f4f4;
  display: inline-block;
  padding: 10px 16px;
}
.chat-content-assistant {
  display: flex;
}
.chat-content,.chat-content-assistant {
  border-radius: 6px;
  //color: #0d0d0d;
  max-width: 730px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: left;
  //white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-break: anywhere;
}

.search-input {
  border-radius:8px;
  background-color:#fff;
  padding:10px 20px;
  width:760px;
  /* margin:0px auto; */
  /* box-shadow: 5px 4px 6px #edf3ff, 5px 8px 12px #edf3ff; */
  border: 1px solid #ebeefa;
}

.search-input.focused {
  border-color: var(--el-color-primary);
}

.batch-info {
  width:32px;
  height:32px;
  background-color:#f7f7f7;
  border-radius: 50%;
}

.ai-robot-img {
  width: 30px;
  height: 30px;
  margin-top: -5px;
  margin-right:10px;
}

.search-extra-list {
  width:90px;
  margin:0 auto;
  display: flex;
  align-items:center;
  padding:10px;
  border-radius:6px;
  cursor:pointer;
}

.search-extra-list:hover {
  background-color:#EBEDF3;
}

.chat-extra {
  border: 1px solid #f4f4f4;padding: 10px 16px;border-radius:6px;margin-bottom:4px;
}

.chat-box-item {
  width:40%;text-align: left;box-sizing: border-box;
}

.chat-box-item div{
  text-align:left;padding:4px 10px;cursor:pointer;line-height: 2;border-radius: 8px;display: inline-block;border:1px solid #dcdee2;
}

</style>
<style>
.github-markdown-body {
  padding:0px !important;
  font-size:14px !important;
  line-height:2;
}

.github-markdown-body li {
  //margin: 0 !important;
}
.github-markdown-body li>p {
  //margin: 0px !important;
  display: inline;
}
.github-markdown-body h1, .github-markdown-body h2, .github-markdown-body h3, .github-markdown-body h4, .github-markdown-body h5, .github-markdown-body h6 {
  margin: 0 !important;
  line-height: 2;
}

</style>
