<template>
  <div id="BusinessSegments">
    <div style="width:78vw;text-align:left;">
      <h1 class="set-title">业务板块</h1>
    </div>
    <div class="ai-module">
      <el-row :gutter="30">
        <el-col :span="12" class="mt-20">
          <a href="https://greentms.crrcgce.com/big/#/" target="_blank">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-image style="width: 545px; height: 303px" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/zdt-cy.png"  />
                <span class="mt-15 fw-500" style="font-size:18px;">智慧车运</span>
              </div>
            </el-card>
          </a>
        </el-col>
        <el-col :span="12" class="mt-20">
          <a href="https://jg.senyuanyuan.com/admin/#/login?source=Y3JlZW5CaWc%3D&secret=Y3JlZW5CaWcxMjM0" target="_blank">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-image style="width: 545px; height: 303px" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/xlt-platform.png"  />
                <span class="mt-15 fw-500" style="font-size:18px;">智慧垃圾分类</span>
              </div>
            </el-card>
          </a>
        </el-col>
      </el-row>
      <el-row :gutter="30" class="mt-20">
        <el-col :span="12" class="mt-20">
          <a href="https://cold-chain.crrcgce.com/#/pages/fullscreen/cold-box-screen?tokens=90cc4e3dc487ac2cf97e50bc998d67246ee1f6af15769d0e95f1087009528b59" target="_blank">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-image style="width: 545px; height: 303px" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/ll-yy.png"  />
                <span class="mt-15 fw-500" style="font-size:18px;">智慧冷链集配</span>
              </div>
            </el-card>
          </a>
        </el-col>
        <el-col :span="12" class="mt-20">
          <a href="https://cold-chain.crrcgce.com/#/pages/fullscreen/cold-machine-screen?tokens=ffbeb0b6cb9018c8e6b85872ca815fcf645b8f01a8be6a4f667fdad767e06cc1" target="_blank">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-image style="width: 545px; height: 303px" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/ll-cl.png"  />
                <span class="mt-15 fw-500" style="font-size:18px;">智慧冷链充冷</span>
              </div>
            </el-card>
          </a>
        </el-col>
      </el-row>

      <el-row :gutter="30" class="mt-20 mb-40">
        <el-col :span="12" class="mt-20">
          <a href="https://www.wanjunshijie.com/demo/shujudaping/guangfuzhineng/vue3/#/" target="_blank">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-image style="width: 545px; height: 303px" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/zhgf.png"  />
                <span class="mt-15 fw-500" style="font-size:18px;">智慧光伏</span>
              </div>
            </el-card>
          </a>
        </el-col>
        <el-col :span="12" class="mt-20">
          <a href="https://www.wanjunshijie.com/demo/shujudaping/zhihuifengdian/vue3/#/" target="_blank">
            <el-card shadow="hover">
              <div class="ai-info">
                <el-image style="width: 545px; height: 303px" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/zgfc.png"  />
                <span class="mt-15 fw-500" style="font-size:18px;">智慧风场</span>
              </div>
            </el-card>
          </a>
        </el-col>
      </el-row>
    </div>
  </div>


</template>
<script>
export default {
  name: 'BusinessSegments',
  setup() {}
}
</script>
<style scoped>
#BusinessSegments {
  min-height: 100vh;
  width:100%;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
  overflow: auto;
}
.set-title {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.ai-module {
  text-align:left;
  width:78vw;
}


</style>