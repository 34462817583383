<template>
  <div class="chat-container">
    <!-- 消息展示区域 -->
    <div class="messages" ref="messagesContainer">
      <div
          v-for="(msg, index) in messages"
          :key="index"
          :class="['message', msg.isUser ? 'user' : 'bot']"
      >
        {{ msg.content }}
      </div>
    </div>

    <!-- 输入区域 -->
    <div class="input-area">
      <input
          v-model="inputMessage"
          @keyup.enter="sendMessage"
          placeholder="输入消息..."
      />
      <button @click="sendMessage">发送</button>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue'

// 消息数据
const messages = ref([
  { content: '你好！有什么可以帮您？', isUser: false }
])
const inputMessage = ref('')
const messagesContainer = ref(null)

// 发送消息逻辑
const sendMessage = async () => {
  if (!inputMessage.value.trim()) return

  // 添加用户消息到顶部
  messages.value.unshift({
    content: inputMessage.value,
    isUser: true
  })

  // 等待DOM更新后滚动
  await nextTick()
  scrollToTop()

  // 模拟AI回复
  setTimeout(async () => {
    messages.value.unshift({
      content: generateResponse(inputMessage.value),
      isUser: false
    })

    await nextTick()
    scrollToTop()
  }, 1000)

  inputMessage.value = ''
}

// 滚动到顶部方法
const scrollToTop = () => {
  if (messagesContainer.value) {
    messagesContainer.value.scrollTop = 0
  }
}

// 模拟AI生成回复
const generateResponse = (input) => {
  const responses = [
    `已收到：${input}`,
    `正在处理您的请求：${input}`,
    `明白，关于${input}的回复是...`,
    `${input}？这个问题需要仔细分析`
  ]
  return responses[Math.floor(Math.random() * responses.length)]
}
</script>

<style scoped>
.chat-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  background: #f5f5f5;
}

.messages {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column-reverse;
}

.message {
  max-width: 70%;
  padding: 15px 20px;
  margin: 10px 0;
  border-radius: 18px;
  transition: all 0.3s ease;
  word-break: break-word;
}

.user {
  align-self: flex-end;
  background: #007bff;
  color: white;
  box-shadow: 0 2px 5px rgba(0,123,255,0.2);
}

.bot {
  align-self: flex-start;
  background: white;
  color: #333;
  min-height: 60px;  /* AI消息最小高度 */
  display: flex;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  border: 1px solid #eee;
}

.input-area {
  padding: 20px;
  display: flex;
  gap: 15px;
  background: white;
  border-top: 1px solid #ddd;
}

input {
  flex: 1;
  padding: 12px 18px;
  border: 1px solid #ddd;
  border-radius: 25px;
  font-size: 16px;
  transition: all 0.3s ease;
}

input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0,123,255,0.1);
}

button {
  padding: 12px 25px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  background: #0056b3;
  transform: translateY(-1px);
}

/* 滚动条样式 */
.messages::-webkit-scrollbar {
  width: 8px;
}

.messages::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.messages::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.messages::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}
</style>