<template>
  <el-main style="position:relative;padding:0px;background-color: #f6f7f9;display:flex;align-items: center;justify-content: center;">
    <div style="box-sizing: border-box;display: flex;align-items: center;justify-content: space-between;padding: 13px 20px;width: 100%;position:absolute;top:0;left:0;">
      <div class="fw-600 fs-14">DS-R1</div>
      <div>
        <img v-show="loginStatus==0" class="cursor-pointer" @click="userOperate" style="width:20px;height:20px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/main_me.png" alt="">
        <el-popover :width="160">
          <div>
            <div class="flex flex-align-center fs-12">
              <el-icon><Avatar /></el-icon>
              <span class="ml-5">{{userInfo.userName}}</span>
            </div>
            <el-divider style="margin:10px 0px;"/>
            <div class="fs-12">
              <router-link to="/vip-order">
                <div class="user-list-item flex flex-align-center color-normal">
                  <el-icon><Tickets /></el-icon><span class="ml-5">订单信息</span>
                </div>
              </router-link>
              <!--          <div class="user-list-item flex flex-align-center">-->
              <!--            <el-icon><Medal /></el-icon><span class="ml-5">会员信息</span>-->
              <!--          </div>-->
            </div>
            <div class="mt-10" >
<!--              <router-link to="/vip-pay" v-show="userInfo.userLevel == 0">-->
<!--                <el-button type="success" icon="Medal" color="#444" class="vip-pay">开通会员</el-button>-->
<!--              </router-link>-->
<!--              <el-button type="success" icon="Medal" color="#444" class="vip-pay" v-show="userInfo.userLevel == 1">6小时卡会员</el-button>-->
<!--              <el-button type="success" icon="Medal" color="#444" class="vip-pay" v-show="userInfo.userLevel == 2">日卡会员</el-button>-->
<!--              <el-button type="success" icon="Medal" color="#444" class="vip-pay" v-show="userInfo.userLevel == 3">周卡卡会员</el-button>-->
<!--              <el-button type="success" icon="Medal" color="#444" class="vip-pay" v-show="userInfo.userLevel == 4">月卡会员</el-button>-->
<!--              <div class="mt-10 text-left">-->
<!--                <router-link to="/vip-pay" v-show="userInfo.userLevel != 0">-->
<!--                  <el-button size="small" type="success"  color="#444" class="vip-continue" v-show="userInfo.userLevel == 1">续费/升级</el-button>-->
<!--                </router-link>-->
<!--              </div>-->

            </div>
          </div>
          <template #reference>
            <img v-show="loginStatus==1" class="cursor-pointer" @click="userOperate" style="width:20px;height:20px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/main_me.png" alt="">
          </template>
        </el-popover>
      </div>
    </div>
    <div style="width:100%;height:100%;display:flex;align-items: center;justify-content: center;">
      <div style="width:60%;height:70%;">
        <div class="text-left flex flex-align-center">
          <img src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/%E6%B3%A1%E6%B3%A1_soap-bubble.png" alt="" style="width:20px;height:20px;">
          <span class="ml-10 fw-600 fs-26">Hi, 有什么可以帮忙的吗</span>
        </div>

        <div class="text-left mt-20">
          <span class="fs-14">把握AIGC时代的个人力量：抓住前言技术的机遇，与我们一起成为创新的超级个体</span>
        </div>

        <div class="flex mt-20 space-between" >
          <div style="width:64%">
            <div class="text-item-container mt-20 fw-600 cursor-pointer" @click="askAISomething(1)"><span class=" color-main">创意写作</span> <span class="ml-16">论文 | 社媒文案 | 作文 | 改写 | 汇报</span></div>
            <div class="text-item-container mt-20 fw-600 cursor-pointer" @click="askAISomething(2)"><span class=" color-main">文案写手</span> <span class="ml-16">一键快速生成超棒的小红书文案</span></div>
            <div class="text-item-container mt-20 fw-600 cursor-pointer" @click="askAISomething(3)"><span class=" color-main">快速翻译</span> <span class="ml-16">中英互译一键搞定，精准又高效</span></div>
          </div>
          <div style="width:34%;padding:20px 0;">
            <el-carousel indicator-position="none" width="100%" height="255px" style="border-radius: 6px;">
              <el-carousel-item>
                <router-link to="/travel">
                <div style="cursor:pointer;width:100%;height:100%;background-size:cover;background-image: url('https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/20250321155032.png')">
                  <div class="fw-600 color-normal" style="position: absolute;top:10px;left:10px;background-color:#f6f7fab3;padding:4px 10px;border-radius:10px;font-size:10px;">最热智能体</div>
                  <div class="text-left" style="position: absolute;bottom:10px;left:10px;color:#fff;padding:4px;">
                    <div class="fs-18">旅行推荐官</div>
                    <div class="fs-12 mt-10">根据用户需求生成量身定制的旅游规划方案</div>
                  </div>
                </div>
                </router-link>
              </el-carousel-item>
              <el-carousel-item>
                <router-link to="/english">
                <div style="cursor:pointer;width:100%;height:100%;background-size:cover;background-image: url('https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/20250321155419.png')">
                  <div class="fw-600 color-normal" style="position: absolute;top:10px;left:10px;background-color:#f6f7fab3;padding:4px 10px;border-radius:10px;font-size:10px;">最热智能体</div>
                  <div class="text-left" style="position: absolute;bottom:10px;left:10px;color:#fff;padding:4px;">
                    <div class="fs-18">英语训练师</div>
                    <div class="fs-12 mt-10">Passionate and open-minded English Consultation</div>
                  </div>
                </div>
                </router-link>
              </el-carousel-item>
              <el-carousel-item >
                <router-link to="/news">
                <div style="cursor:pointer;width:100%;height:100%;background-size:cover;background-image: url('https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/20250321155806.png')">
                  <div class="fw-600 color-normal" style="position: absolute;top:10px;left:10px;background-color:#f6f7fab3;padding:4px 10px;border-radius:10px;font-size:10px;">最热智能体</div>
                  <div class="text-left" style="position: absolute;bottom:10px;left:10px;color:#fff;padding:4px;">
                    <div class="fs-18">时事热点眼</div>
                    <div class="fs-12 mt-10">你可以从我这里掌握第一时间最新最酷的热点信息</div>
                  </div>
                </div>
                </router-link>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>

    <div class="search-input" :class="{ focused: isFocused }">
      <div class="flex flex-center">
        <input v-model="newMessage" type="text" class="no-border" style="padding:10px;width:900px;font-size:14px;" placeholder="请输入问题" @focus="handleFocus" @blur="handleBlur" @keyup.enter="sendMessage">
        <div class="flex flex-center ml-10">
          <el-image style="width: 32px; height: 32px" class="cursor-pointer"
                    src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/new-arrow-circle-up.png" @click="sendMessage"/>
        </div>
      </div>
    </div>
  </el-main>

  <Login :loginFlag="loginFlag" @update-prop="updateLoginFlag" />
</template>

<script setup>
import {onMounted, ref} from "vue";
import Login from "../components/common/Login.vue";
import {apiGetUserLevel} from "../api/user/user";
import {apiGetUserInfo} from "../api/user/login";
import {Avatar, Tickets} from "@element-plus/icons-vue";
import {ElMessage} from "element-plus";
import {apiCreateTalkRecord} from "../api/chat/chat";
import router from "../router";

const isFocused = ref(false);
const newMessage = ref('');

const handleFocus = () => {
  isFocused.value = true;
}
const handleBlur = () =>{
  isFocused.value = false;
}

const loginFlag = ref(false);

const loginStatus = ref(localStorage.getItem('is_login'));
const userOperate = () => {
  let lsLogin = localStorage.getItem('is_login');
  if (parseInt(lsLogin) !== 1) {
    loginFlag.value = true;
  }
}

function updateLoginFlag(newValue, isLogin) {
  loginFlag.value = newValue;
  if (parseInt(isLogin) === 1) {
    initOperation();
  }
}

const initOperation = () => {
  getUserInfo();
  getUserLevel();
}

const userInfo = ref({
  userName: '',
  userLevel: 0,//0表示试用用户 1表示6小时用户
});
const getUserInfo = () => {
  apiGetUserInfo().then(res => {
    if (res.data.err_no === 0) {
      let data = res.data.results;
      localStorage.setItem('user_info', JSON.stringify(data));
      userInfo.value.userName = data.phone;
      loginStatus.value = 1;
    } else {
      localStorage.setItem('is_login', 0);
      loginStatus.value = 0;
    }
  })
}
const getUserLevel = () => {
  apiGetUserLevel().then(res => {
    if (res.data.err_no == 0) {
      localStorage.setItem('user_level', 0);
      if (res.data.results.account_type !== undefined) {
        userInfo.value.userLevel = res.data.results.account_type;
        localStorage.setItem('user_level', userInfo.value.userLevel);
      }
    }
  })
}

onMounted(() => {
  if (parseInt(loginStatus.value) === 1) {
    initOperation();
  }
});

const todayDate = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = (now.getMonth() + 1).toString().padStart(2, '0');
  const day = now.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const askAISomething = (item) => {
  newMessage.value = "你好"
  if (parseInt(item) === 1) {
    newMessage.value = "我今天去了苏州博物馆，拍下了雪后的博物馆，要发朋友圈，请你为我写一个简短的朋友圈文案"
  }
  if (parseInt(item) === 2) {
    newMessage.value = "你是小红书文案写手，帮我写一篇文案：上海藏在巷子里的宝藏美食"
  }
  if (parseInt(item) === 3) {
    newMessage.value = "请将下文翻译成中文：I'm not proud of everything I did, but I'm pretty sure I'd do it all again."
  }
  sendMessage();
}

const engineFlag = ref(6)//默认启用deepseek
const sendMessage = () => {
  if (parseInt(localStorage.getItem('is_login')) !== 1) {
    ElMessage({
      message: '请先登录后操作！',
      type: 'warning',
    })
    return;
  }

  //判断用户等级 试用用户每天只可以问三次
  if (parseInt(localStorage.getItem('user_level')) === 0) {
    let timesKey = 'user_test_times';
    let todayDateStr = todayDate();
    let user_test_times = JSON.parse(localStorage.getItem(timesKey));
    if (user_test_times==null ||(user_test_times.date_info != undefined && user_test_times.date_info != todayDate)) {
      user_test_times = {
        times: 0,
        date_into: todayDateStr
      }
    }

    if (parseInt(user_test_times.times) >= 3) {
      ElMessage({
        message: '试用用户每天最多可以使用3次！',
        type: 'warning',
      })
      return;
    }

    user_test_times.times = user_test_times.times + 1;
    localStorage.setItem(timesKey, JSON.stringify(user_test_times));
  }

  if (newMessage.value.trim() !== '') {
    // eslint-disable-next-line no-unused-vars
    let ai_type_str = 'zhipuai';
    let engine_type = 'zhipuai';

    if (engineFlag.value == 2) {
      ai_type_str = 'kimi';
      engine_type = 'kimi';
    }

    if (engineFlag.value == 3) {
      ai_type_str = 'tyqw';
      engine_type = 'tyqw';
    }

    if (engineFlag.value == 4) {
      ai_type_str = 'chatgpt';
      engine_type = 'gpt-4o-mini';
    }

    if (engineFlag.value == 6) {
      ai_type_str = 'deepseek';
      engine_type = 'deepseek-r1';
    }

    // if (engineFlag.value == 5) {
    //   ai_type_str = 'dall-e-3';
    //   engine_type = 'dall-e-3';
    //   router.push({  name: 'ImgChat',query: { ...router.currentRoute.value.query, ai_type: ai_type_str,content: newMessage.value.trim(),engine_type:engine_type} });
    //   return;
    // }


    //生成新对话框
    let param = {
      engine_type: engine_type,
      talk_content: newMessage.value.trim()
    }
    apiCreateTalkRecord(param).then(res => {
      if (res.data.err_no == 0 && res.data.results != "") {
        const current_text_session_key = "current_text_session_id";
        localStorage.setItem(current_text_session_key, res.data.results);


        router.push({  name: 'Chat',query: { ...router.currentRoute.value.query, ai_type: engine_type,session_id: res.data.results} });
      }
    })

  }
}
</script>

<style scoped>
.text-item-container {
  max-height: 72px;
  height: 72px;
  border-radius: 16px;
  background: #ffffff99;
  padding: 16px;
  border: 1px solid #1a202914;
  box-shadow: 0 4px 16px 0 #2454ff0a;
  box-sizing: border-box;
  position: relative;
  flex-shrink: 1;
  flex-grow: 0;
  display: flex
;
  align-items: center;
}


.search-input {
  position: absolute;
  bottom:20px;
  border-radius:8px;
  background-color:#fff;
  padding:10px 20px;
  width:55vw;
  margin:30px auto;
  box-shadow: 5px 4px 6px #edf3ff, 5px 8px 12px #edf3ff;
  border: 1px solid transparent;
}

.search-input.focused {
  border-color: var(--el-color-primary);
}

.vip-pay {
  width:100%;
  color:#f6ca9d !important;
  font-size:12px;
}
</style>