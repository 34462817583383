import request from "@/plugins/http";


export function apiElevatorCreateConversation(param) {
    return request.post('/aiApi/elevatorCreateConversation', param);
}

export function apiGetElevatorRecord() {
    return request.post('/api/talk/getElevatorRecord');
}

export function apiSetElevatorRecord(param) {
    return request.post('/api/talk/setElevatorRecord', param);
}

export function apiTravelCreateConversation(param) {
    return request.post('/aiApi/travelCreateConversation', param);
}






