<!-- src/views/Home.vue -->
<template>
  <div id="ai-index">
    <AiHeader />

    <div class="ai-body">
      <div class="flex flex-center space-around" style="margin-top:60px;">
        <div class="flex">
          <!-- <div class="flex flex-center"> <span class="fs-14 fw-bold" style="color:#595959;">对话模型:</span></div> -->
          <div :class="['flex','cursor-pointer', 'flex-center', 'ml-10', 'ai-title-info' ,engineFlag==3?'object-active':'']"  @click="chooseAiEngine(3)">
            <el-image class="ai-title-img" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/tyqw.png" /> 
            <span class="ml-10 fw-bold">通义千问</span>
          </div>

<!--          <div :class="['flex','cursor-pointer', 'flex-center', 'ml-10', 'ai-title-info' ,engineFlag==1?'object-active':'']"  @click="chooseAiEngine(1)">-->
<!--            <el-image class="ai-title-img" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAABQBJREFUWEe1WM1uI0UQrm/sIKFFIpE4IGxpHZIDt3ifAO8TJHmC9XLiluSG1pY8IxzELd4nwLlwJXmCdSTueO8JDJKNuOFIiYS0nv5Qz3jimXHPnxPm2NNd9XX9fFVdEMO3Y7MtwjOAb697Fdu0J1xr2GyIyKZrY5y1T//bcbyhEPsieH1j4yJvv/4PI0DHG0LwSkTeX/esZpqgXcezRdDz/1PG17b1IkvpjqNmEPlUhE7exUM5RoANm80KVFtojbJuuuMoFyLPQ2Fz4kWWJXdsHgCqOac1cG3M1rZgkYOBy9QFRPYjALeKKi6qw2jBood3bbYIHkOkQWJwY2NY9GzRfY8CWFTJY/YVAljvsiXCI1IaIhx7nuX8/SPcNMW1Do8B+q4nMZye4nxdkLkAv+jywBL+klAwu6tie2YI9HpHXQiWcRkkON9O+5XjdUDmAqx1lAssMzVUQuJkeopBVGntDZuw+JsJyHyO7Syrp4H3AdY63lAEe9p99xvWSdQy9a6i+TCdST9O4vVuhBdXDuHlpI9RuKw5lPS5duwJnDR6Qu0N27D4U5pl0iyoyXYVoB+r70wXosKL6Q9BtdHVpwr+8aBTeH7Tq7RN57B667ji5AVCIUpw+Fd/tVzVu0pb6euYMsrl5NQ6CNd0IagiFgpX1z2rZQT4+XdsVCoc+XFGuZ17aCZjZZHFuiZHFMddFgrftLn5bK50CfQVQniRtLRe33WUtubeItN1bTZy6EOSaBDRGDHdRl+mWuEgyNJVF5fNUm1JEXGzqk9uFpuU1rpqDJE9UsYANK2MJt/jqizAIvvXAmiMM5EZyeH9huWY+LEIGGMMRheDeFRHAJokx0LrPMy8GN+lcGMQTzKenq62XTo2P5nLKxEekHQB6zwvpIIYXnx+fFX9zNqMgJlR4WUUZEplSVw+Hp9+4nzgO0BivWUaE0SFPQDUZA34xBn/EhRR63oDCI6yXJa04qI2nxnOzCZ9ayu5rvtGEbXviXUSyWIDfwUuc6en1nYoJCX+VnRP+tZStqE+hwfuqthKxuyuE1YvOvkWFLma9JckmmrpCESKvJ/2l0+FrBIYvUgoYvGUaM0J3WsGX3qhjxNykRhMdi9+gnygK9DvkeiXz6UxmvFBgjZFmoC4IrBNmVbvqNmqskCxtt59Fa2k20KSD2WTuEh2Q6a4Xo8H02KKcnm3gfb/xoNZmRn9Fzal2lq61lJZ7v1HcvGUwEJ9pSy4INsjEdqSoJ+ilyu7Dw2bmxVRZ0H3wdGNXXmdFKJjU+BXGN0yLYjc3M3os4tE8jlVCc5NbdmXtn4NUvNpdsOqxxxArGE9jD7W07PWDNBMQ/Fs1Uapgv+EhmBWw5oHML2jltFdFYfRuMviyOibpBRA38VQgwcXJ1rv9DeJX2X+VERfLHkG8lsL8lV6jMUt7rsY1C+9sUfYqW+SvKBN5TzKrQd8o/6VX7UMUD6rfMyfdZ9okhl9k+TpjP7PzWJjmaLckmglW7Gg3edoBeQjMj4XoF8GO9oV2iXyXHOfKLRNfaLeG7RWahB2Rrrs3Vcte12OLASwjEueeu+jAZaZsK4D/lEAy05YnwygtooF2VeUS9dOn2KVnbAGk1vZ8wK5609Y9bDbn1HnzJ3LTljDGbUiTn6344OnNOsaXaxJ1ALPKHJ501uOLJJCyk5Yd53gWUH6U/5C09jUGNQVpqgbysRWWbn/AVADzOfEpLliAAAAAElFTkSuQmCC" /> -->
<!--            <span class="ml-10 fw-bold">智谱AI</span>-->
<!--          </div>-->
          <div :class="['flex','cursor-pointer', 'flex-center', 'ml-10', 'ai-title-info' ,engineFlag==6?'object-active':'']"  @click="chooseAiEngine(6)">
            <el-image class="ai-title-img" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/deepseek.png" />
            <span class="ml-10 fw-bold">DeepSeek</span>
          </div>

          <div :class="['flex','cursor-pointer', 'flex-center', 'ml-10', 'ai-title-info' ,engineFlag==2?'object-active':'']"  @click="chooseAiEngine(2)">
            <el-image class="ai-title-img" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/kimi.png" /> 
            <span class="ml-10 fw-bold">Kimi.ai</span>
          </div>

          <div :class="['flex','cursor-pointer', 'flex-center', 'ml-10', 'ai-title-info' ,engineFlag==4?'object-active':'']"  @click="chooseAiEngine(4)">
            <el-image class="ai-title-img" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAABCxJREFUWEflmT1MVEEUhc+lABPxJ9FIoEJNpIKYSAONULDYaCBSKQaoAFsipQZLEyxlqQAFKxWCDT8Fa8HaQIJQYYJSQTASMUIiFDvmzHjZx2ZxB3bBNU6yCZk37803595zZzIIAq03ZJqMmGYYXIXgbPDZkf9tsAHBnBjpb52QAZ1P+EdflTm7nWeGAVQdOYjfBJG8balviciGBQzXxqayCE6XEGkbz6mWnhrTLDmmz29hxzvKxKRFekKxiAiuH+/UfrMZg3cSDsW+Hbsh/PgAgw0J18aM7/i/Me7/ACwqA/IvOH3XP7lfplpaChZXAJVtglMFe3HWl4CpbpMR0EMDltQAVR1iIWZeGKzMA3n5ANUsbxTkFwCLE67v65LB6gLsmIO2QwHmngTuDgh+fAFe3d/rMQISXFXlAs5d+h3+JWD8scGPNX/MAwNyMipUXAm8bIpPRiCCEXBzDZgOGyy/j4NQcabD9qZb1M6WH6Q3IFWr7nBgbKvzwGinAfsJXFoPOynDvTCSfHIuruGZ4OOky1Gf5g3ID1OlhWFYyJ1NB0jFbj4RC0U4VcZC1wGfo0C0N95PFdnfeyODgJzsWiMQ6TZYnARuPbFnjF1APicsmzqbYaYp+B4XMz9sMDvkntc+ErztdMZK1bwUtIZYc0BsyQCZc1SHitK9kaduLFVnP8H4jeUobDpkFLB1TDA7CMwMJgesfSiAuBDTtWqG2cG4SgRnvzq677afUVIqSAXuDPwZkBOPPtg7oaYF1Zwdirtd+6Ph/c0UDHtKQA5ueS02NOo8hpjuZcjPX3Yu1vDrx1UxDen8G5eDbMzBolKgryG1UbwAbXmpcEAMIVVlH3cLAhAyGaCCqzFY//i+On+8a2+tTGYYL0C7czx3RZZOVvdxIoJSh2C/1kxuc8FSFDRGYl7v52YvQL7MOqgJnphXrGvl9wSfp+lUg7J6t0cHSxFrpQJqwc6YgpyIK2YxZk1jmaBsrG22bwvQwwN3GOYq00BDrCFVQDVKcKtMW8GgUXTyKzWutvF3usCBafiZd1QyMcRME5qM+7TPducdYnXeUFO8nFCZ0nrByod4yWD+VbYKSkKwEAyjmoSG4js8BSWWpbQV1DAFd4nEj+r+m5sPW9iZAgy/7r8cz4MCd52Mn2b4cc0dhnFhOH5ILSx1JmHoqRqLsJ75qCgNxr15rMsfTBfvHWJ9gWbQE3NQQQIEc5DPguH23XsTo3JgwOBOoWXnYqWgsMydXpajTj0+Kwk5VfUUlOrkcuhC7fNhqkp1ubtoY8nhAcPnWJW2SXwgOYaK8ZcOVHCuQ4fYFzjdcf8AYCjGm80z6a70SN43+J79129Zf4HJ0GTjJSYvL9sncqp2L9F/5pqRbLlpJdyJHanbvUTXBGe4IaZZYP8NcbzGMfhugDkY6W+flH5l+gXPQW6akzXdQgAAAABJRU5ErkJggg==" />
            <span class="ml-10 fw-bold">ChatGPT</span>
          </div>

          <el-popover
              :width="260"
              popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;"
            >
            <template #reference>
              <div class="flex flex-center ml-10 ai-title-info other-ai cursor-pointer" >
                <el-icon class="ai-title-img"><Grid /></el-icon>
                <span class="ml-10 fw-bold">其他模型</span>
                <el-icon class="ml-10 arrow-change" ><ArrowDown /></el-icon>
              </div>
            </template>
            <template #default>
              <div style="display: flex; gap: 16px; flex-direction: column">
<!--                <router-link :to="{ name: 'Chat', query: { ...$route.query, ai_type: 'chatgpt', engine_type: 'gpt-4o-2024-08-06' } }">-->
                  <div class="hide-item-engin">
                    <el-image class="ai-title-img" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/gpt4.svg" />
                    <span class="ml-10 fw-bold">GPT-4o-2024-08-06</span>
                  </div>
<!--                </router-link>-->

<!--                <router-link :to="{ name: 'Chat', query: { ...$route.query, ai_type: 'chatgpt', engine_type: 'gpt-4o' } }">-->
                  <div class="hide-item-engin">
                    <el-image class="ai-title-img" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/gpt4.svg" />
                    <span class="ml-10 fw-bold">GPT-4o</span>
                  </div>
<!--                </router-link>-->

<!--                <router-link :to="{ name: 'Chat', query: { ...$route.query, ai_type: 'chatgpt', engine_type: 'gpt-4-turbo' } }">-->
                  <div class="hide-item-engin">
                    <el-image class="ai-title-img" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/gpt4.svg" />
                    <span class="ml-10 fw-bold">GPT-4-turbo</span>
                  </div>
<!--                </router-link>-->

<!--                <router-link :to="{ name: 'Chat', query: { ...$route.query, ai_type: 'chatgpt', engine_type: 'gpt-4o-mini' } }">-->
                  <div class="hide-item-engin">
                    <el-image class="ai-title-img" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/gpt4.svg" />
                    <span class="ml-10 fw-bold">GPT-4o-mini</span>
                  </div>
<!--                </router-link>-->

<!--                <router-link :to="{ name: 'Chat', query: { ...$route.query, ai_type: 'chatgpt', engine_type: 'gpt-3.5-turbo' } }">-->
                  <div class="hide-item-engin">
                    <el-image class="ai-title-img" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/gpt3.svg" />
                    <span class="ml-10 fw-bold">GPT-3.5-turbo</span>
                  </div>
<!--                </router-link>-->

<!--                <router-link :to="{ name: 'Chat', query: { ...$route.query, ai_type: 'chatgpt', engine_type: 'gpt-3.5-turbo-instruct' } }">-->
                  <div class="hide-item-engin">
                    <el-image class="ai-title-img" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/gpt3.svg" />
                    <span class="ml-10 fw-bold">GPT-3.5-turbo-instruct</span>
                  </div>
<!--                </router-link>-->

<!--                <router-link :to="{ name: 'Chat', query: { ...$route.query, ai_type: 'chatgpt', engine_type: 'claude-3.5-sonnet' } }">-->
                  <div class="hide-item-engin">
                    <el-image class="ai-title-img" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/claude.svg" />
                    <span class="ml-10 fw-bold">Claude-3.5-Sonnet</span>
                  </div>
<!--                </router-link>-->

<!--                <router-link :to="{ name: 'Chat', query: { ...$route.query, ai_type: 'chatgpt', engine_type: 'llama-3.1-405b' } }">-->
                  <div class="hide-item-engin">
                    <el-image class="ai-title-img" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/llama3.png" />
                    <span class="ml-10 fw-bold">LLama-3.1-405b</span>
                  </div>
<!--                </router-link>-->

<!--                <router-link :to="{ name: 'Chat', query: { ...$route.query, ai_type: 'chatgpt', engine_type: 'llama-3.1-70b' } }">-->
                  <div class="hide-item-engin">
                    <el-image class="ai-title-img" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/llama3.png" />
                    <span class="ml-10 fw-bold">LLama-3.1-70b</span>
                  </div>
<!--                </router-link>-->

<!--                <router-link :to="{ name: 'Chat', query: { ...$route.query, ai_type: 'chatgpt', engine_type: 'llama-3-70b' } }">-->
                  <div class="hide-item-engin">
                    <el-image class="ai-title-img" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/llama3.png" />
                    <span class="ml-10 fw-bold">LLama-3-70b</span>
                  </div>
<!--                </router-link>-->
              </div>
            </template>
          </el-popover>
        </div>
      </div>

      <div class="search-input" :class="{ focused: isFocused }">
        <div class="flex flex-center">
          <!-- <div class="flex flex-center" style="width:32px;height:32px;background-color:#f7f7f7;border-radius: 50%;">
            <el-popover
                :width="140"
                popper-style="box-shadow: rgb(14 18 22 / 35%) 0px 10px 38px -10px, rgb(14 18 22 / 20%) 0px 10px 20px -15px; padding: 20px;margin-top:20px;"
                trigger="click"
            >
              <template #reference>
                <el-icon class="cursor-pointer"><Expand /></el-icon>
              </template>
              <template #default style="margin-top:20px;">
                <div style="display: flex; gap: 16px; flex-direction: column;justify-content: center;">
                  <div class="search-extra-list" >
                    <el-icon><Upload /></el-icon>
                    <span class="ml-10 fw-bold">文件解读</span>
                  </div>
                </div>
              </template>
            </el-popover>
          </div> -->
          <input v-model="newMessage" type="text" class="no-border" style="padding:10px;width:900px;font-size:14px;" placeholder="请输入问题" @focus="handleFocus" @blur="handleBlur" @keyup.enter="sendMessage">
          <div class="flex flex-center ml-10">
            <el-image style="width: 32px; height: 32px" class="cursor-pointer"
            src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/arrow-circle-up.png" @click="sendMessage"/>
          </div>
        </div>
      </div>

      <el-row :gutter="20">
        <el-col :span="5">
          <div class="flex flex-column m-item-list" >
            <div :class="['flex', 'flex-center', 'm-item','cursor-pointer',  mItemIndex==1?'m-item-active':'']" @click="selectMItem(1)">
              <div class="flex flex-center color-main m-item-icon ">
                  <el-icon class="fs-18"><Sunny /></el-icon>
              </div>
              <div class="ml-16 text-left w-108">
                <div class="m-item-title">热门对话</div>
                <div class="m-item-desc">向我提出任何问题</div>
              </div>
            </div>
            <div :class="['flex', 'flex-center', 'm-item','cursor-pointer',  mItemIndex==2?'m-item-active':'']" @click="selectMItem(2)" >
              <div class="flex flex-center color-main m-item-icon">
                  <el-icon class="fs-18"><Connection /></el-icon>
              </div>
              <div class="ml-16 text-left w-108">
                <div class="m-item-title">定制模型</div>
                <div class="m-item-desc">个性化定制大模型</div>
              </div>
            </div>
            <div :class="['flex', 'flex-center', 'm-item','cursor-pointer',  mItemIndex==3?'m-item-active':'']" @click="selectMItem(3)">
              <div class="flex flex-center color-main m-item-icon">
                  <el-icon class="fs-18"><User /></el-icon>
              </div>
              <div class="ml-16 text-left w-108">
                <div class="m-item-title">角色扮演</div>
                <div class="m-item-desc">与不同角色对话</div>
              </div>
            </div>
            <div :class="['flex', 'flex-center', 'm-item','cursor-pointer',  mItemIndex==4?'m-item-active':'']" @click="selectMItem(4)">
              <div class="flex flex-center color-main m-item-icon">
                  <el-icon class="fs-18"><Files /></el-icon>
              </div>
              <div class="ml-16 text-left w-108">
                <div class="m-item-title">文件解读</div>
                <div class="m-item-desc">解读论文合同等文件</div>
              </div>
            </div>
            <div :class="['flex', 'flex-center', 'm-item','cursor-pointer',  mItemIndex==5?'m-item-active':'']" @click="selectMItem(5)">
              <div class="flex flex-center color-main m-item-icon">
                  <el-icon class="fs-18"><Histogram /></el-icon>
              </div>
              <div class="ml-16 text-left w-108">
                <div class="m-item-title">统计图</div>
                <div class="m-item-desc">根据要求生成统计图</div>
              </div>
            </div>
            <div :class="['flex', 'flex-center', 'm-item','cursor-pointer',  mItemIndex==6?'m-item-active':'']" @click="selectMItem(6)">
              <div class="flex flex-center color-main m-item-icon">
                  <el-icon class="fs-18"><PictureFilled /></el-icon>
              </div>
              <div class="ml-16 text-left w-108">
                <div class="m-item-title">图片解读</div>
                <div class="m-item-desc">解读X光片/看图看代码</div>
              </div>
            </div>
            <div :class="['flex', 'flex-center', 'm-item','cursor-pointer',  mItemIndex==7?'m-item-active':'']" @click="selectMItem(7)">
              <div class="flex flex-center color-main m-item-icon">
                  <el-icon class="fs-18"><Finished /></el-icon>
              </div>
              <div class="ml-16 text-left w-108">
                <div class="m-item-title">流程图</div>
                <div class="m-item-desc">根据要求生成流程图</div>
              </div>
            </div>
            <div :class="['flex', 'flex-center', 'm-item','cursor-pointer',  mItemIndex==8?'m-item-active':'']" @click="selectMItem(8)">
              <div class="flex flex-center color-main m-item-icon">
                  <el-icon class="fs-18"><Guide /></el-icon>
              </div>
              <div class="ml-16 text-left w-108">
                <div class="m-item-title">思维导图</div>
                <div class="m-item-desc">根据要求生成思维导图</div>
              </div>
            </div>
            <div :class="['flex', 'flex-center', 'm-item','cursor-pointer',  mItemIndex==9?'m-item-active':'']" @click="selectMItem(9)">
              <div class="flex flex-center color-main m-item-icon">
                  <el-icon class="fs-18"><SetUp /></el-icon>
              </div>
              <div class="ml-16 text-left w-108">
                <div class="m-item-title">个人模板</div>
                <div class="m-item-desc">将常用的提问存为模板</div>
              </div>
            </div>
          </div>
        </el-col>
        
        <el-col :span="19" v-if="mItemIndex==9">
          <div class="m-item-content">
            <div class="flex" style="color: #a1a1a1;font-size: 13px;">
              <div>模板：</div>
              <div>
                <span :class="['ml-16', 'cursor-pointer', pmiFlag==1?'hot-talk-content-active':'']" @click="choosePmi(1)">语言学习</span>
                <span :class="['ml-16', 'cursor-pointer', pmiFlag==2?'hot-talk-content-active':'']" @click="choosePmi(2)">代码开发</span>
                <span :class="['ml-16', 'cursor-pointer', pmiFlag==3?'hot-talk-content-active':'']"
                @click="choosePmi(3)">文案生成</span>
                <span :class="['ml-16', 'cursor-pointer', pmiFlag==4?'hot-talk-content-active':'']" @click="choosePmi(4)">学术帮助</span>
                <span :class="['ml-16', 'cursor-pointer', pmiFlag==5?'hot-talk-content-active':'']" @click="choosePmi(5)">效率办公</span>
                <span :class="['ml-16', 'cursor-pointer', pmiFlag==6?'hot-talk-content-active':'']" @click="choosePmi(6)">学业辅导</span>
                <span :class="['ml-16', 'cursor-pointer', pmiFlag==7?'hot-talk-content-active':'']" @click="choosePmi(7)">生活助手</span>
                <span :class="['ml-16', 'cursor-pointer', pmiFlag==8?'hot-talk-content-active':'']" @click="choosePmi(8)">旅游攻略</span>
              </div>
            </div>
            <div class="mt-20">
              <el-row :gutter="20" v-if="pmiFlag==1">
                <el-col :span="8" v-for="item in pMContent.language_study" >
                  <div class="text-left p-m-list" @click="setSendMessage(item.content)">
                    <div>
                      <span class="fs-14 fw-400" style="color:#141414;">{{item.title}}</span>
                    </div>
                    <div>
                      <span class="fs-12 fw-400" style="color:#8c8c8c;">{{item.desc}}</span>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="p-m-detail">
                      <span class="fs-12 fw-400" >{{item.content}}</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="20" v-if="pmiFlag==2">
                <el-col :span="8" v-for="item in pMContent.code_develop" >
                  <div class="text-left p-m-list" @click="setSendMessage(item.content)">
                    <div>
                      <span class="fs-14 fw-400" style="color:#141414;">{{item.title}}</span>
                    </div>
                    <div>
                      <span class="fs-12 fw-400" style="color:#8c8c8c;">{{item.desc}}</span>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="p-m-detail">
                      <span class="fs-12 fw-400" >{{item.content}}</span>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="19" v-if="mItemIndex==1">
          <div class="m-item-content">
            <div class="flex" style="color: #a1a1a1;font-size: 13px;">
              <div>筛选：</div>
              <div>
                <span class="ml-16 cursor-pointer hot-talk-content-active">全部</span>
                <span class="ml-16 cursor-pointer">智谱AI</span>
                <span class="ml-16 cursor-pointer">Kimi.ai</span>
                <span class="ml-16 cursor-pointer">通义千问</span>
              </div>
            </div>
            <div class="mt-20">
              <el-row :gutter="20">
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div style="color:#434343;">
                        <el-icon><Plus /></el-icon>
                        <div class="fs-14">立即体验"热门对话"</div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">我希望你能作为一本自助书。你将为我提供如何改善我生活中某些领域的建议和提示，如人际关系、职业发展或财务规划。例如，如果我在与重要的另一半的关系中挣扎，你可以建议有用的沟通技巧，使我们的关系更紧密。</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">当然可以！作为一本自助书，我会为您提供关于如何改善生活中各个领域的建议和提示。在此过程中，我会尽量使用实际案例和易于实践的方法来帮助您实现目标。以下是关于如何改善人际关系、职业发展和财务规划的一些建议。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">我希望你能作为一本自助书。你将为我提供如何改善我生活中某些领域的建议和提示，如人际关系、职业发展或财务规划。例如，如果我在与重要的另一半的关系中挣扎，你可以建议有用的沟通技巧，使我们的关系更紧密。</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">当然可以！作为一本自助书，我会为您提供关于如何改善生活中各个领域的建议和提示。在此过程中，我会尽量使用实际案例和易于实践的方法来帮助您实现目标。以下是关于如何改善人际关系、职业发展和财务规划的一些建议。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">我希望你能作为一本自助书。你将为我提供如何改善我生活中某些领域的建议和提示，如人际关系、职业发展或财务规划。例如，如果我在与重要的另一半的关系中挣扎，你可以建议有用的沟通技巧，使我们的关系更紧密。</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">当然可以！作为一本自助书，我会为您提供关于如何改善生活中各个领域的建议和提示。在此过程中，我会尽量使用实际案例和易于实践的方法来帮助您实现目标。以下是关于如何改善人际关系、职业发展和财务规划的一些建议。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">我希望你能作为一本自助书。你将为我提供如何改善我生活中某些领域的建议和提示，如人际关系、职业发展或财务规划。例如，如果我在与重要的另一半的关系中挣扎，你可以建议有用的沟通技巧，使我们的关系更紧密。</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">当然可以！作为一本自助书，我会为您提供关于如何改善生活中各个领域的建议和提示。在此过程中，我会尽量使用实际案例和易于实践的方法来帮助您实现目标。以下是关于如何改善人际关系、职业发展和财务规划的一些建议。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">希望有人有足够的经验来区分各种类型的茶叶，根据味道特征仔细品尝，然后用行家使用的行话报告，以便找出任何给定的茶叶的独特之处，从而确定其价值和高品位的质量。</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">茶叶品尝是一种需要经验和专业知识的技能,以下是一些常用的茶叶品尝术语,有助于区分不同类型的茶叶,并识别其独特之处:
外观:茶叶的外观特征可以提供有关其质量的线索。例如,茶叶的颜色、形状、大小、质地和均匀性。
香气:香气是茶叶的重要特征之一。通过闻茶叶的香气,可以识别其类型和质量。例如,绿茶通常具有清新的香气,而红茶则具有浓郁的香气。
味道:品尝茶叶的味道可以帮助识别其种类和品质。例如,绿茶通常具有清淡的味道,而红茶则具有浓郁的味道。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">我希望你能作为一本自助书。你将为我提供如何改善我生活中某些领域的建议和提示，如人际关系、职业发展或财务规划。例如，如果我在与重要的另一半的关系中挣扎，你可以建议有用的沟通技巧，使我们的关系更紧密。</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">当然可以！作为一本自助书，我会为您提供关于如何改善生活中各个领域的建议和提示。在此过程中，我会尽量使用实际案例和易于实践的方法来帮助您实现目标。以下是关于如何改善人际关系、职业发展和财务规划的一些建议。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                
              </el-row>
              
            </div>
          </div>
        </el-col>

        <el-col :span="19" v-if="mItemIndex==2">
          <div class="m-item-content">
            <div class="flex" style="color: #a1a1a1;font-size: 13px;">
              <div>发现定制模型：</div>
              <div>
                <span class="ml-16 cursor-pointer hot-talk-content-active">热门</span>
                <span class="ml-16 cursor-pointer">科研</span>
                <span class="ml-16 cursor-pointer">职场</span>
                <span class="ml-16 cursor-pointer">学习</span>
                <span class="ml-16 cursor-pointer">娱乐</span>
                <span class="ml-16 cursor-pointer">其他</span>
              </div>
            </div>
            <div class="mt-20">
              <el-row :gutter="20">
                <el-col :span="8" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div style="display:flex;align-items:center;margin:0 auto;justify-content: center;color:#2775ff;background-color:#edf3ff;border-radius:6px;padding:10px;width:140px;font-weight:600;">
                      <el-icon><Plus /></el-icon>
                      <div class="ml-10 fs-14">创建定制模型</div>
                    </div>
                      <div style="color:#434343;margin-top:29px;">
                        <div class="fs-12">无需任何代码，即刻开始创造，<br/>
                          自主定制模型数据，轻松打造从0到1的个性化智能体</div>
                      </div>
                    </el-card>
                </el-col>

                <el-col :span="8" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div class="flex">
                      <div style="width:64px;">
                        <el-image style="width: 64px; height: 64px;border-radius:6px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/workplace_3.png" />
                      </div>
                      <div class="ml-10">
                        <div class="fw-700 fa-14 text-left">实验助手</div>
                        <div  style="overflow-y:auto;max-height:38px;line-height: 18px;"><span class="fs-12">实验助手是一位熟练的实验技术专家，擅长提供实验设计、数据分析和方法优化方面的支持。具备广泛的科研经验，可为用户提供高效准确的实验相关信息。</span></div>
                      </div>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="flex flex-align-center" style="justify-content:space-between;">
                      <div class="flex flex-align-center">
                        <el-icon style="color:#e37171;"><Sunny /></el-icon>
                        <span class="fs-12 ml-5" style="color:#8c8c8c;">2300热度</span>
                      </div>
                      <el-icon><Star /></el-icon>
                      <el-icon><Share /></el-icon>
                      <el-button type="primary" size="small" round plain>开始聊天</el-button>
                    </div>
                  </el-card>
                </el-col>

                <el-col :span="8" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div class="flex">
                      <div style="width:64px;">
                        <el-image style="width: 64px; height: 64px;border-radius:6px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/workplace_8.png" />
                      </div>
                      <div class="ml-10">
                        <div class="fw-700 fa-14 text-left">法律顾问</div>
                        <div  style="overflow-y:auto;max-height:38px;line-height: 18px;"><span class="fs-12">法律顾问拥有深厚的法律知识库和案例分析能力，专注于提供全面的法律咨询服务，包括合同审核、争议解决和法律风险评估，旨在为非法专业人士提供即时、准确的法律指导。</span></div>
                      </div>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="flex flex-align-center" style="justify-content:space-between;">
                      <div class="flex flex-align-center">
                        <el-icon style="color:#e37171;"><Sunny /></el-icon>
                        <span class="fs-12 ml-5" style="color:#8c8c8c;">19421热度</span>
                      </div>
                      <el-icon><Star /></el-icon>
                      <el-icon><Share /></el-icon>
                      <el-button type="primary" size="small" round plain>开始聊天</el-button>
                    </div>
                  </el-card>
                </el-col>

                <el-col :span="8" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div class="flex">
                      <div style="width:64px;">
                        <el-image style="width: 64px; height: 64px;border-radius:6px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/entertainment_10.png" />
                      </div>
                      <div class="ml-10">
                        <div class="fw-700 fa-14 text-left">段子手</div>
                        <div  style="overflow-y:auto;max-height:38px;line-height: 18px;"><span class="fs-12">”段子手“是一位幽默感十足的喜剧创作专家，擅长编织笑话和有趣故事，能够根据不同话题即兴创作，让你捧腹大笑。</span></div>
                      </div>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="flex flex-align-center" style="justify-content:space-between;">
                      <div class="flex flex-align-center">
                        <el-icon style="color:#e37171;"><Sunny /></el-icon>
                        <span class="fs-12 ml-5" style="color:#8c8c8c;">22355热度</span>
                      </div>
                      <el-icon><Star /></el-icon>
                      <el-icon><Share /></el-icon>
                      <el-button type="primary" size="small" round plain>开始聊天</el-button>
                    </div>
                  </el-card>
                </el-col>

                <el-col :span="8" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div class="flex">
                      <div style="width:64px;">
                        <el-image style="width: 64px; height: 64px;border-radius:6px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/entertainment_8.png" />
                      </div>
                      <div class="ml-10">
                        <div class="fw-700 fa-14 text-left">答案之书</div>
                        <div  style="overflow-y:auto;max-height:38px;line-height: 18px;"><span class="fs-12">“答案之书”是一款全能型问答助手，旨在为各类问题提供明确、精准的解答，无论是科学、历史还是日常生活，所有答案尽在此书。</span></div>
                      </div>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="flex flex-align-center" style="justify-content:space-between;">
                      <div class="flex flex-align-center">
                        <el-icon style="color:#e37171;"><Sunny /></el-icon>
                        <span class="fs-12 ml-5" style="color:#8c8c8c;">16441热度</span>
                      </div>
                      <el-icon><Star /></el-icon>
                      <el-icon><Share /></el-icon>
                      <el-button type="primary" size="small" round plain>开始聊天</el-button>
                    </div>
                  </el-card>
                </el-col>

                <el-col :span="8" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div class="flex">
                      <div style="width:64px;">
                        <el-image style="width: 64px; height: 64px;border-radius:6px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/entertainment_1.png" />
                      </div>
                      <div class="ml-10">
                        <div class="fw-700 fa-14 text-left">AI算命</div>
                        <div  style="overflow-y:auto;max-height:38px;line-height: 18px;"><span class="fs-12">善于结合深度学习与传统占卜艺术，专精于紫微斗数、八字、风水等预测学，运用大数据分析与人工智能技术，为你提供个性化的命运解读与指导建议。</span></div>
                      </div>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="flex flex-align-center" style="justify-content:space-between;">
                      <div class="flex flex-align-center">
                        <el-icon style="color:#e37171;"><Sunny /></el-icon>
                        <span class="fs-12 ml-5" style="color:#8c8c8c;">37282热度</span>
                      </div>
                      <el-icon><Star /></el-icon>
                      <el-icon><Share /></el-icon>
                      <el-button type="primary" size="small" round plain>开始聊天</el-button>
                    </div>
                  </el-card>
                </el-col>

                <el-col :span="8" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div class="flex">
                      <div style="width:64px;">
                        <el-image style="width: 64px; height: 64px;border-radius:6px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/study_2.png" />
                      </div>
                      <div class="ml-10">
                        <div class="fw-700 fa-14 text-left">英语口语老师</div>
                        <div  style="overflow-y:auto;max-height:38px;line-height: 18px;"><span class="fs-12">资深英语口语老师，拥有TEFL认证，专长于辅导各水平学员掌握地道口音和实用表达。结合个性化教学方案和实时互动，致力于优化学习体验，使学习者在各种社交、商务和学术场景下自如交流。</span></div>
                      </div>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="flex flex-align-center" style="justify-content:space-between;">
                      <div class="flex flex-align-center">
                        <el-icon style="color:#e37171;"><Sunny /></el-icon>
                        <span class="fs-12 ml-5" style="color:#8c8c8c;">16649热度</span>
                      </div>
                      <el-icon><Star /></el-icon>
                      <el-icon><Share /></el-icon>
                      <el-button type="primary" size="small" round plain>开始聊天</el-button>
                    </div>
                  </el-card>
                </el-col>

                <el-col :span="8" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div class="flex">
                      <div style="width:64px;">
                        <el-image style="width: 64px; height: 64px;border-radius:6px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/workplace_2.png" />
                      </div>
                      <div class="ml-10">
                        <div class="fw-700 fa-14 text-left">数据分析师</div>
                        <div  style="overflow-y:auto;max-height:38px;line-height: 18px;"><span class="fs-12">这是一名专业的科研数据分析师，擅长使用先进的统计方法解析复杂数据集，助力科研人员洞察数据背后的真相，优化研究方向。</span></div>
                      </div>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="flex flex-align-center" style="justify-content:space-between;">
                      <div class="flex flex-align-center">
                        <el-icon style="color:#e37171;"><Sunny /></el-icon>
                        <span class="fs-12 ml-5" style="color:#8c8c8c;">29940热度</span>
                      </div>
                      <el-icon><Star /></el-icon>
                      <el-icon><Share /></el-icon>
                      <el-button type="primary" size="small" round plain>开始聊天</el-button>
                    </div>
                  </el-card>
                </el-col>

                <el-col :span="8" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div class="flex">
                      <div style="width:64px;">
                        <el-image style="width: 64px; height: 64px;border-radius:6px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/study_5.png" />
                      </div>
                      <div class="ml-10">
                        <div class="fw-700 fa-14 text-left">思维导师</div>
                        <div  style="overflow-y:auto;max-height:38px;line-height: 18px;"><span class="fs-12">“思维导师”是一位资深认知发展专家，精通心理学与教育学，专注于培养个体的批判性和创造性思维技巧，深谙多元思维框架，并能够根据不同年龄和职业背景提供个性化的思维策略训练。</span></div>
                      </div>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="flex flex-align-center" style="justify-content:space-between;">
                      <div class="flex flex-align-center">
                        <el-icon style="color:#e37171;"><Sunny /></el-icon>
                        <span class="fs-12 ml-5" style="color:#8c8c8c;">10602热度</span>
                      </div>
                      <el-icon><Star /></el-icon>
                      <el-icon><Share /></el-icon>
                      <el-button type="primary" size="small" round plain>开始聊天</el-button>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>

        <el-col :span="19" v-if="mItemIndex==3">
          <div class="m-item-content">
            <div class="flex" style="color: #a1a1a1;font-size: 13px;">
              <div>角色对话：</div>
              <div>
                <span class="ml-16 cursor-pointer hot-talk-content-active">热门</span>
                <span class="ml-16 cursor-pointer">心里咨询</span>
                <span class="ml-16 cursor-pointer">游戏</span>
                <span class="ml-16 cursor-pointer">影视</span>
                <span class="ml-16 cursor-pointer">动漫</span>
                <span class="ml-16 cursor-pointer">学术类</span>
                <span class="ml-16 cursor-pointer">语言类</span>
                <span class="ml-16 cursor-pointer">文字创作</span>
                <span class="ml-16 cursor-pointer">职业指导</span>
                <span class="ml-16 cursor-pointer">其他类</span>
              </div>
            </div>
            <div class="mt-20">
              <el-row :gutter="20">
                <el-col :span="12" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div class="flex">
                      <div style="width:64px;">
                        <el-image style="width: 64px; height: 64px;border-radius:6px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/workplace_3.png" />
                      </div>
                      <div class="ml-10">
                        <div class="fw-700 fa-14 text-left">编写小助手</div>
                        <div  style="overflow-y:auto;max-height:38px;line-height: 18px;"><span class="fs-12">在过去的几年里，我已经帮助无数用户完成了数以百计的心得、日志、观后感、实践报告和入党申请书等内容创作任务。通过我的帮助，用户们节省了大量时</span></div>
                      </div>
                    </div>
                    <div class="flex flex-align-center fs-12 space-between mt-10" style="background-color:#edf3ff;padding:6px 10px;border-radius:6px;">
                      <div><span style="color:#8c8c8c;">试着说</span></div>
                      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: calc(100% - 60px);color: #595959;margin-left:2px;">
                        <span>"我需要写一篇观后感，但总是不知道从何入手，怎样才能写得更有深度和表达力呢？"</span>
                      </div>
                      <div class="color-main">
                        <el-icon><ChatDotSquare /></el-icon>
                      </div>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="flex flex-align-center" style="justify-content:space-between;">
                      <div class="flex flex-align-center">
                        <el-icon style="color:#e37171;"><Sunny /></el-icon>
                        <span class="fs-12 ml-5" style="color:#8c8c8c;">225910热度</span>
                      </div>
                      <div class="flex flex-align-center">
                        <el-icon class="color-main"><Star /></el-icon>
                        <el-icon class="ml-10 color-main"><Share /></el-icon>
                        <el-button class="ml-10" type="primary" size="small" round plain>开始聊天</el-button>
                      </div>
                    </div>
                  </el-card>
                </el-col>

                <el-col :span="12" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div class="flex">
                      <div style="width:64px;">
                        <el-image style="width: 64px; height: 64px;border-radius:6px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/workplace_8.png" />
                      </div>
                      <div class="ml-10">
                        <div class="fw-700 fa-14 text-left">学术专家</div>
                        <div  style="overflow-y:auto;max-height:38px;line-height: 18px;"><span class="fs-12">作为学术领域的引领者，我在各个领域拥有丰富的学术经验与专业知识，不仅参与前沿研究，还积极分享经验与见解，为学术界的进步贡献力量，可以帮你进行学术研究和指导。</span></div>
                      </div>
                    </div>
                    <div class="flex flex-align-center fs-12 space-between mt-10" style="background-color:#edf3ff;padding:6px 10px;border-radius:6px;">
                      <div><span style="color:#8c8c8c;">试着说</span></div>
                      <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;width: calc(100% - 60px);color: #595959;margin-left:2px;">
                        <span>"我在学术研究中总是感到困惑，如何突破瓶颈呢？"</span>
                      </div>
                      <div class="color-main">
                        <el-icon><ChatDotSquare /></el-icon>
                      </div>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="flex flex-align-center" style="justify-content:space-between;">
                      <div class="flex flex-align-center">
                        <el-icon style="color:#e37171;"><Sunny /></el-icon>
                        <span class="fs-12 ml-5" style="color:#8c8c8c;">195690热度</span>
                      </div>
                      <div class="flex flex-align-center">
                        <el-icon class="color-main"><Star /></el-icon>
                        <el-icon class="ml-10 color-main"><Share /></el-icon>
                        <el-button class="ml-10" type="primary" size="small" round plain>开始聊天</el-button>
                      </div>
                    </div>
                  </el-card>
                </el-col>

                <el-col :span="12" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div class="flex">
                      <div style="width:64px;">
                        <el-image style="width: 64px; height: 64px;border-radius:6px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/entertainment_10.png" />
                      </div>
                      <div class="ml-10">
                        <div class="fw-700 fa-14 text-left">作家</div>
                        <div  style="overflow-y:auto;max-height:38px;line-height: 18px;"><span class="fs-12">一位擅长写故事、续写小说、和提供写作创意灵感的创作专家。我致力于为您的作品提供续写、故事创作和创新点的服务，让您的作品在文学创作领域脱颖而出，引发更多读者的共鸣和关注。</span></div>
                      </div>
                    </div>
                    <div class="flex flex-align-center fs-12 space-between mt-10" style="background-color:#edf3ff;padding:6px 10px;border-radius:6px;">
                      <div class="flex flex-align-center" style="width: calc(100% - 60px);">
                        <div><span style="color:#8c8c8c;">试着说</span></div>
                        <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color: #595959;margin-left:2px;">
                          <span>"如何才能写出畅销千万的故事"</span>
                        </div>
                      </div>
                      <div class="color-main">
                        <el-icon><ChatDotSquare /></el-icon>
                      </div>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="flex flex-align-center" style="justify-content:space-between;">
                      <div class="flex flex-align-center">
                        <el-icon style="color:#e37171;"><Sunny /></el-icon>
                        <span class="fs-12 ml-5" style="color:#8c8c8c;">152600热度</span>
                      </div>
                      <div class="flex flex-align-center">
                        <el-icon class="color-main"><Star /></el-icon>
                        <el-icon class="ml-10 color-main"><Share /></el-icon>
                        <el-button class="ml-10" type="primary" size="small" round plain>开始聊天</el-button>
                      </div>
                    </div>
                  </el-card>
                </el-col>

                <el-col :span="12" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div class="flex">
                      <div style="width:64px;">
                        <el-image style="width: 64px; height: 64px;border-radius:6px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/entertainment_8.png" />
                      </div>
                      <div class="ml-10">
                        <div class="fw-700 fa-14 text-left">抖音文案大师</div>
                        <div  style="overflow-y:auto;max-height:38px;line-height: 18px;"><span class="fs-12">一位经验丰富、能力出众的文案和视频脚本写手，熟悉抖音平台的用户喜好和趋势，能够准确把握受众心理，擅长为抖音视频制作吸引人的文案。致力于为您的抖音视频提供精彩的文案创意，让您的作品在抖音平台上脱颖而出，吸引更多的用户关注和点赞。</span></div>
                      </div>
                    </div>
                    <div class="flex flex-align-center fs-12 space-between mt-10" style="background-color:#edf3ff;padding:6px 10px;border-radius:6px;">
                      <div class="flex flex-align-center" style="width: calc(100% - 30px);">
                        <div style="width:80px;"><span style="color:#8c8c8c;">试着说</span></div>
                        <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color: #595959;margin-left:2px;">
                          <span>"我在抖音上想制作有趣的视频，但总是难以找到合适的文案，如何让我的视频更具吸引力和创意呢？"</span>
                        </div>
                      </div>
                      <div class="color-main">
                        <el-icon><ChatDotSquare /></el-icon>
                      </div>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="flex flex-align-center" style="justify-content:space-between;">
                      <div class="flex flex-align-center">
                        <el-icon style="color:#e37171;"><Sunny /></el-icon>
                        <span class="fs-12 ml-5" style="color:#8c8c8c;">90480热度</span>
                      </div>
                      <div class="flex flex-align-center">
                        <el-icon class="color-main"><Star /></el-icon>
                        <el-icon class="ml-10 color-main"><Share /></el-icon>
                        <el-button class="ml-10" type="primary" size="small" round plain>开始聊天</el-button>
                      </div>
                    </div>
                  </el-card>
                </el-col>

                <el-col :span="12" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div class="flex">
                      <div style="width:64px;">
                        <el-image style="width: 64px; height: 64px;border-radius:6px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/entertainment_1.png" />
                      </div>
                      <div class="ml-10">
                        <div class="fw-700 fa-14 text-left">学术论文润色助手</div>
                        <div  style="overflow-y:auto;max-height:38px;line-height: 18px;"><span class="fs-12">我擅长学术写作规范，提升论文的品质与影响力，精细润色每个细节，优化语言表达与逻辑结构；助你创造出精确、清晰、令人钦佩的学术论文。</span></div>
                      </div>
                    </div>
                    <div class="flex flex-align-center fs-12 space-between mt-10" style="background-color:#edf3ff;padding:6px 10px;border-radius:6px;">
                      <div class="flex flex-align-center text-left" style="width: calc(100% - 30px);">
                        <div style="width:40px;"><span style="color:#8c8c8c;">试着说</span></div>
                        <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color: #595959;margin-left:2px;">
                          <span>"我总是担心我的学术论文写作是否符合标准，怎么办？"</span>
                        </div>
                      </div>
                      <div class="color-main">
                        <el-icon><ChatDotSquare /></el-icon>
                      </div>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="flex flex-align-center" style="justify-content:space-between;">
                      <div class="flex flex-align-center">
                        <el-icon style="color:#e37171;"><Sunny /></el-icon>
                        <span class="fs-12 ml-5" style="color:#8c8c8c;">72270热度</span>
                      </div>
                      <div class="flex flex-align-center">
                        <el-icon class="color-main"><Star /></el-icon>
                        <el-icon class="ml-10 color-main"><Share /></el-icon>
                        <el-button class="ml-10" type="primary" size="small" round plain>开始聊天</el-button>
                      </div>
                    </div>
                  </el-card>
                </el-col>

                <el-col :span="12" style="margin-bottom:16px;">
                  <el-card shadow="hover">
                    <div class="flex">
                      <div style="width:64px;">
                        <el-image style="width: 64px; height: 64px;border-radius:6px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/study_2.png" />
                      </div>
                      <div class="ml-10">
                        <div class="fw-700 fa-14 text-left">高级代码工程师</div>
                        <div  style="overflow-y:auto;max-height:38px;line-height: 18px;"><span class="fs-12">熟悉并精通多种编程语言，包括但不限于Java、Python、C++等，能够根据项目需求选择最适合的编程语言，并编写高效、可靠的代码。帮你快速定位和修复代码中的bug，并提供高质量的解决方案。</span></div>
                      </div>
                    </div>
                    <div class="flex flex-align-center fs-12 space-between mt-10" style="background-color:#edf3ff;padding:6px 10px;border-radius:6px;">
                      <div class="flex flex-align-center text-left" style="width: calc(100% - 30px);">
                        <div style="width:40px;"><span style="color:#8c8c8c;">试着说</span></div>
                        <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;color: #595959;margin-left:2px;width: calc(100% - 40px);">
                          <span>"我正在进行一个复杂的编程项目，但遇到了一些难以解决的问题。我不确定如何优化性能，也不知道如何处理一些复杂的逻辑。"</span>
                        </div>
                      </div>
                      <div class="color-main">
                        <el-icon><ChatDotSquare /></el-icon>
                      </div>
                    </div>
                    <el-divider style="margin:12px 0;"/>
                    <div class="flex flex-align-center" style="justify-content:space-between;">
                      <div class="flex flex-align-center">
                        <el-icon style="color:#e37171;"><Sunny /></el-icon>
                        <span class="fs-12 ml-5" style="color:#8c8c8c;">70480热度</span>
                      </div>
                      <div class="flex flex-align-center">
                        <el-icon class="color-main"><Star /></el-icon>
                        <el-icon class="ml-10 color-main"><Share /></el-icon>
                        <el-button class="ml-10" type="primary" size="small" round plain>开始聊天</el-button>
                      </div>
                    </div>
                  </el-card>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-col>

        <el-col :span="19" v-if="mItemIndex==4">
          <div class="m-item-content">
            <div class="flex" style="color: #a1a1a1;font-size: 13px;">
              <div>筛选：</div>
              <div>
                <span class="ml-16 cursor-pointer hot-talk-content-active">全部</span>
                <span class="ml-16 cursor-pointer">智谱AI</span>
                <span class="ml-16 cursor-pointer">Kimi.ai</span>
                <span class="ml-16 cursor-pointer">通义千问</span>
              </div>
            </div>
            <div class="mt-20">
              <el-row :gutter="20">
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div style="color:#434343;" class="cursor-pointer" @click="fileParseJump">
                        <el-icon><Plus /></el-icon>
                        <div class="fs-14">立即体验"文件解读"</div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div style="overflow:hidden;">
                        <div class="flex" style="width:100%;">
                          <div style="width:10%">
                            <el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10" style="width:85%">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">总结一下这篇文献的内容</div>
                            <div style="border:1px solid #eaeaea;padding:8px 12px;display:flex;align-items: center;font-size:12px;background-color:#fff;white-space: nowrap;overflow: hidden;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;border-radius:6px;">
                              <el-icon style="color:#f57373;"><Document /></el-icon>
                              <span class="ml-2">国内深静脉血栓预防护理现状的调查研究.pdf</span>
                            </div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">目的：研究的目的是了解中国医院对外科患者深静脉血栓防治的现状，以及临床护士对深静脉血栓预防的认识程度，从而为外科临床护理工作提供指导。

方法：研究采用的是自行设计的问卷。在2013年中华护理学会外科护理学术研讨会上，对全国25个省市的225名临床护士进行了调查。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">金字塔原理主要讲解什么内容</div>
                            <div style="border:1px solid #eaeaea;padding:8px 12px;display:flex;align-items: center;font-size:12px;background-color:#fff;white-space: nowrap;overflow: hidden;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;border-radius:6px;">
                              <el-icon style="color:#f57373;"><Document /></el-icon>
                              <span class="ml-2">《金字塔原理》.pdf</span>
                            </div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">金字塔原理（The Pyramid Principle）是由前麦肯锡咨询公司的顾问芭芭拉·明托（Barbara Minto）创立的一种沟通技巧。这一原理主要用于业务写作和咨询报告，也广泛应用于管理沟通和个人呈现。金字塔原理的核心内容包括：

逻辑结构：信息应该呈现出逻辑上的层级关系，类似于一座金字塔。这意味着要从最重要的观点开始，然后分别支持每个观点的支持点。

思考顺序：金字塔原理鼓励先呈现结论，然后再给出支持结论的论据。这种方式迎合了受众首先想知道结论的心理，并且可以帮助受众更快地理解和记住信息。

剖分原则（MECE Principle）：在构建支持点时，内容应该是相互排斥的，共同穷尽的（Mutually Exclusive, Collectively Exhaustive），确保信息不重复且完整。

答疑式结构（Question-Answer Structure）：先提出问题，然后给出答案。在沟通中，我们应该识别受众可能的问题，并在提供信息时预先给出解答。

简洁清晰：使用简单直观的语言和图表来呈现复杂的内容，使接收信息的人能够快速理解和作出反应。

金字塔原理不仅是一种写作方法，也是一种思维方式，它倡导清晰、有条理地组织思想和信息，以提高沟通效率。通过使用金字塔原理，可以使报告、演讲、电子邮件和其他商业文档的受众更快地捕捉要点，并作出决策。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">金字塔原理主要讲解什么内容</div>
                            <div style="border:1px solid #eaeaea;padding:8px 12px;display:flex;align-items: center;font-size:12px;background-color:#fff;white-space: nowrap;overflow: hidden;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;border-radius:6px;">
                              <el-icon style="color:#f57373;"><Document /></el-icon>
                              <span class="ml-2">《金字塔原理》.pdf</span>
                            </div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">金字塔原理（The Pyramid Principle）是由前麦肯锡咨询公司的顾问芭芭拉·明托（Barbara Minto）创立的一种沟通技巧。这一原理主要用于业务写作和咨询报告，也广泛应用于管理沟通和个人呈现。金字塔原理的核心内容包括：

逻辑结构：信息应该呈现出逻辑上的层级关系，类似于一座金字塔。这意味着要从最重要的观点开始，然后分别支持每个观点的支持点。

思考顺序：金字塔原理鼓励先呈现结论，然后再给出支持结论的论据。这种方式迎合了受众首先想知道结论的心理，并且可以帮助受众更快地理解和记住信息。

剖分原则（MECE Principle）：在构建支持点时，内容应该是相互排斥的，共同穷尽的（Mutually Exclusive, Collectively Exhaustive），确保信息不重复且完整。

答疑式结构（Question-Answer Structure）：先提出问题，然后给出答案。在沟通中，我们应该识别受众可能的问题，并在提供信息时预先给出解答。

简洁清晰：使用简单直观的语言和图表来呈现复杂的内容，使接收信息的人能够快速理解和作出反应。

金字塔原理不仅是一种写作方法，也是一种思维方式，它倡导清晰、有条理地组织思想和信息，以提高沟通效率。通过使用金字塔原理，可以使报告、演讲、电子邮件和其他商业文档的受众更快地捕捉要点，并作出决策。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">金字塔原理主要讲解什么内容</div>
                            <div style="border:1px solid #eaeaea;padding:8px 12px;display:flex;align-items: center;font-size:12px;background-color:#fff;white-space: nowrap;overflow: hidden;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;border-radius:6px;">
                              <el-icon style="color:#f57373;"><Document /></el-icon>
                              <span class="ml-2">《金字塔原理》.pdf</span>
                            </div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">金字塔原理（The Pyramid Principle）是由前麦肯锡咨询公司的顾问芭芭拉·明托（Barbara Minto）创立的一种沟通技巧。这一原理主要用于业务写作和咨询报告，也广泛应用于管理沟通和个人呈现。金字塔原理的核心内容包括：

逻辑结构：信息应该呈现出逻辑上的层级关系，类似于一座金字塔。这意味着要从最重要的观点开始，然后分别支持每个观点的支持点。

思考顺序：金字塔原理鼓励先呈现结论，然后再给出支持结论的论据。这种方式迎合了受众首先想知道结论的心理，并且可以帮助受众更快地理解和记住信息。

剖分原则（MECE Principle）：在构建支持点时，内容应该是相互排斥的，共同穷尽的（Mutually Exclusive, Collectively Exhaustive），确保信息不重复且完整。

答疑式结构（Question-Answer Structure）：先提出问题，然后给出答案。在沟通中，我们应该识别受众可能的问题，并在提供信息时预先给出解答。

简洁清晰：使用简单直观的语言和图表来呈现复杂的内容，使接收信息的人能够快速理解和作出反应。

金字塔原理不仅是一种写作方法，也是一种思维方式，它倡导清晰、有条理地组织思想和信息，以提高沟通效率。通过使用金字塔原理，可以使报告、演讲、电子邮件和其他商业文档的受众更快地捕捉要点，并作出决策。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">金字塔原理主要讲解什么内容</div>
                            <div style="border:1px solid #eaeaea;padding:8px 12px;display:flex;align-items: center;font-size:12px;background-color:#fff;white-space: nowrap;overflow: hidden;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;border-radius:6px;">
                              <el-icon style="color:#f57373;"><Document /></el-icon>
                              <span class="ml-2">《金字塔原理》.pdf</span>
                            </div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">金字塔原理（The Pyramid Principle）是由前麦肯锡咨询公司的顾问芭芭拉·明托（Barbara Minto）创立的一种沟通技巧。这一原理主要用于业务写作和咨询报告，也广泛应用于管理沟通和个人呈现。金字塔原理的核心内容包括：

逻辑结构：信息应该呈现出逻辑上的层级关系，类似于一座金字塔。这意味着要从最重要的观点开始，然后分别支持每个观点的支持点。

思考顺序：金字塔原理鼓励先呈现结论，然后再给出支持结论的论据。这种方式迎合了受众首先想知道结论的心理，并且可以帮助受众更快地理解和记住信息。

剖分原则（MECE Principle）：在构建支持点时，内容应该是相互排斥的，共同穷尽的（Mutually Exclusive, Collectively Exhaustive），确保信息不重复且完整。

答疑式结构（Question-Answer Structure）：先提出问题，然后给出答案。在沟通中，我们应该识别受众可能的问题，并在提供信息时预先给出解答。

简洁清晰：使用简单直观的语言和图表来呈现复杂的内容，使接收信息的人能够快速理解和作出反应。

金字塔原理不仅是一种写作方法，也是一种思维方式，它倡导清晰、有条理地组织思想和信息，以提高沟通效率。通过使用金字塔原理，可以使报告、演讲、电子邮件和其他商业文档的受众更快地捕捉要点，并作出决策。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">金字塔原理主要讲解什么内容</div>
                            <div style="border:1px solid #eaeaea;padding:8px 12px;display:flex;align-items: center;font-size:12px;background-color:#fff;white-space: nowrap;overflow: hidden;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;border-radius:6px;">
                              <el-icon style="color:#f57373;"><Document /></el-icon>
                              <span class="ml-2">《金字塔原理》.pdf</span>
                            </div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">金字塔原理（The Pyramid Principle）是由前麦肯锡咨询公司的顾问芭芭拉·明托（Barbara Minto）创立的一种沟通技巧。这一原理主要用于业务写作和咨询报告，也广泛应用于管理沟通和个人呈现。金字塔原理的核心内容包括：

逻辑结构：信息应该呈现出逻辑上的层级关系，类似于一座金字塔。这意味着要从最重要的观点开始，然后分别支持每个观点的支持点。

思考顺序：金字塔原理鼓励先呈现结论，然后再给出支持结论的论据。这种方式迎合了受众首先想知道结论的心理，并且可以帮助受众更快地理解和记住信息。

剖分原则（MECE Principle）：在构建支持点时，内容应该是相互排斥的，共同穷尽的（Mutually Exclusive, Collectively Exhaustive），确保信息不重复且完整。

答疑式结构（Question-Answer Structure）：先提出问题，然后给出答案。在沟通中，我们应该识别受众可能的问题，并在提供信息时预先给出解答。

简洁清晰：使用简单直观的语言和图表来呈现复杂的内容，使接收信息的人能够快速理解和作出反应。

金字塔原理不仅是一种写作方法，也是一种思维方式，它倡导清晰、有条理地组织思想和信息，以提高沟通效率。通过使用金字塔原理，可以使报告、演讲、电子邮件和其他商业文档的受众更快地捕捉要点，并作出决策。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                
              </el-row>
              
            </div>
          </div>
        </el-col>

        <el-col :span="19" v-if="mItemIndex==5">
          <div class="m-item-content">
            <div class="mt-20">
              <el-row :gutter="20">
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div style="color:#434343;">
                        <el-icon><Plus /></el-icon>
                        <div class="fs-14">立即体验"统计图"</div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div style="overflow:hidden;">
                        <div class="flex" style="width:100%;">
                          <div style="width:10%">
                            <el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10" style="width:85%">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">把下面的数据，生成柱状图统计图:在线服务达到100万用户所需时间：ChatGPT 5天 Instagram 2.5个月 Spotify 5个月 Dropbox 7个月 Facebook 10个月 Foursquare 13个月 Twitter 2年 Airbnb 2.5年 Kickstarter2.5年 Netflix 3.5年</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/9ea97bcb88484eb19d9a0d789edb20e7.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">统计一下数据中每个词出现的频率：{'唐天宝': 4,'十四年': 2,'长安城': 4,'小吏': 2,'李善德': 2,'突然': 5,'接到': 2,'文笔': 1,'行云流水': 1,'医学': 1,'研究生': 1,'大为': 1,'空怀': 1,'壮志': 1,'无职无权': 1,'时来运转': 1,'有名': 2,'有利': 1,'真切': 1,'旅程': 1,'困扰': 1,'傅真': 1,'暌违': 1,'七年': 1,...}并给我一个统计图</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/8112ad9cc1324169b94b8aadeb8caffe.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">在过去 50 年里，中国的人口增长和增长率与美国相比如何？</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/410f043924a54c0b926718e3eafc8ebf.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">黄俊在1月份销售额为50,000人民币，在2月份为40,000人民币，在3月份为30,000人民币。 陈星辰在1月份销售额为60,000人民币，在2月份为20,000人民币，在3月份为75,000人民币。您能把这些数据制成条形图统计图吗？</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/df5351eb3849403198841f7ddf10a9c8.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">制作一个人口最多的5个国家的人口数量饼图统计图，同时分析这些国家未来的人口老龄化问题。</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/175455943d884d75a885e93da6969ff1.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                
              </el-row>
              
            </div>
          </div>
        </el-col>

        <el-col :span="19" v-if="mItemIndex==7">
          <div class="m-item-content">
            <div class="mt-20">
              <el-row :gutter="20">
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div style="color:#434343;">
                        <el-icon><Plus /></el-icon>
                        <div class="fs-14">立即体验"流程图"</div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div style="overflow:hidden;">
                        <div class="flex" style="width:100%;">
                          <div style="width:10%">
                            <el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10" style="width:85%">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">我想做一个公司款项报销的流程，但是我是一个小白，不懂报销流程和报销适用的范围。我希望你作为一个经验丰富的财务人员。请帮我梳理一个报销的完整流程，包括报销注意事项，材料准备，签字审批等等，输出的节点不少于18个，包含逻辑判断的分支，要遵循实事是的原则，不能胡说八道，记住要用 流程图输出，并告知我注意事项。</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/9a19df356b77445e9a4dbee2ef1bdec0.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">生成一个优惠券领取及使用的流程图</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/891a7ed6fcfc46a89636439772491da2.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">我想做一个房地产公司投资决策的流程，但是我是一个小白，不懂房地产公司投资决策的流程。 我希望你作为一个经验丰富的房地产投资拓展从业人员。 请帮我梳理一个房地产投资决策的完整流程，包括项目踏勘、发起立项、集团审批等等，输出的节点不少于18个，包含逻辑判断的分支。 要遵循实事求是的原则，不能胡说八道，记住要用流程图输出。</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/d8b0f813e9524de688ad35fea86d20a5.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">当客户进入奶茶店后，他们首先会浏览店内菜单，选择心仪的奶茶口味和大小，并 告知服务员。服务员会在 POS 系统上输入订单并收取款项。之后，制作人员会在厨 房根据订单开始制作奶茶。他们将倒入奶茶粉和鲜奶，混合搅拌，加入糖浆和其他 配料，如珍珠、椰果等，最后装进杯子中。接下来，服务员检查订单是否正确无误， 如果有问题则退回后台重新制作，如果没问题会将奶茶交给客户，并感谢他们的光 临。最后，客户拿着奶茶离开奶茶店，享受美味的饮品。请将以上内容以流程图的形式进行输出</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/4d73c77493214265b574e10a9f2551d1.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">帮我输出一个创业企业通常要经历的发展过程的流程图</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/6ffb07d7d2934f9da258376b881d5c2d%20%281%29.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                
              </el-row>
              
            </div>
          </div>
        </el-col>

        <el-col :span="19" v-if="mItemIndex==8">
          <div class="m-item-content">
            <div class="mt-20">
              <el-row :gutter="20">
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div style="color:#434343;">
                        <el-icon><Plus /></el-icon>
                        <div class="fs-14">立即体验"思维导图"</div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div style="overflow:hidden;">
                        <div class="flex" style="width:100%;">
                          <div style="width:10%">
                            <el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10" style="width:85%">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">帮我写一篇介绍中国茶叶的分类，包括代表的品类，著名的品牌、产区，每个品种的茶叶的功效、口感介绍。输出为思维导图</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/bbcb89d56b9d419399e1946050dd3457.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div style="overflow:hidden;">
                        <div class="flex" style="width:100%;">
                          <div style="width:10%">
                            <el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10" style="width:85%">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">请从多个角度对中国茶叶进行分类，创建一个经过充分研究的树状层次结构、有洞察力、相关性、详尽的思维导图。列出所有主要分支、子分支，以帮助集思广益，为内容专家和主题专家的终极指南集思广益，思维导图形式输出。</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/d57cd2cff1c74ebba8c5753f02e2268f.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">我是一个测试工程师，这是我收到的需求信息：在 Web 界面上添加一个搜索按钮，要求能够做到关键词精确匹配与模糊匹配。要求根据以上的需求生成测试用例。要求生成的测试用例使用思维导图格式。</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/e9a44d3f15d54b52b66df00a2fe61ab5.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">你现在是一名思维导图专家，我最近想仔细学习一下人工智能相关的知识，请你为我制定一份学习攻略，包括主要方向、重点内容等，输出为思维导图，请仔细想想后回答</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/59f1f9c30474422fb6033294d6ea5d94.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">我想让你充当一名有着十年工作经验的资深前端工程师，帮我写一份主题是《css3的新特性》的思维导图，注意一定要用思维导图格式输出</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/3729087c20ff40cdb166f31302cdceb3.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">如何运营公众号为主题，生成思维导图模块结构化内容，内容以思维导图形式呈现，包括这4大模块：1、什么是公众号； 2、为什么要运营公众号； 3、如何运营公众号； 4、如何利用ChatGPT如何生成短视频？</div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="" class="mt-10">
                              <el-image style="width: 100%; " src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/a515a9837bb54f4aa02a7add6384ee2e.png" />
                            </div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                
              </el-row>
              
            </div>
          </div>
        </el-col>

        <el-col :span="19" v-if="mItemIndex==6">
          <div class="m-item-content">
            <div class="mt-20">
              <el-row :gutter="20">
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div style="color:#434343;">
                        <el-icon><Plus /></el-icon>
                        <div class="fs-14">立即体验"图片解读"</div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div style="overflow:hidden;">
                        <div class="flex" style="width:100%;">
                          <div style="width:10%">
                            <el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10" style="width:85%">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">描述图像中的指定区域。</div>
                            <div style="border:1px solid #eaeaea;padding:8px 12px;display:flex;align-items: center;font-size:12px;background-color:#fff;white-space: nowrap;overflow: hidden;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;border-radius:6px;">
                              <el-image  src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/00a7b17011d00a6870d5e72e84218307.png" /> 
                            </div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">您指的区域被红色圈标记出来，显示的是图像中右侧“Test set”部分的一些数据。在这部分，我们看到了一列名为“C”，代表某种评测指标的分数，和另一列“S”，代表另一种评测指标的分数。特别地，被圈出的数值是“122.3”位于"GT2"行的“out.”的“C”列中，这可能代表这个方法或模型在测试集外部数据上的表现。由于上下文不足，具体含义未知，但一般这种表格可能用于评估不同方法或模型的性能。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">用中文为我解释图中文字的含义</div>
                            <div style="border:1px solid #eaeaea;padding:8px 12px;display:flex;align-items: center;font-size:12px;background-color:#fff;white-space: nowrap;overflow: hidden;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;border-radius:6px;">
                              <el-image  src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/aec947cefd4800452c1889f24f9d9cca.png" /> 
                            </div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">这张图片展示的是一段用英文书写的手稿，内容如下：

A Ship to Sail wit[h]out Winds, and A Ship not to be Sunck: Freedom from Necessity of much Sleeping exemplifyed by Iggorawzon, of Tea and w[h]at happens in Mad Men. Pleasing Dreams & physicall Exercises exemplifyed by O Egyptian Electuary and by the Fungus mentioned by the Fren[ch] Author. Great Strength and Agility of Body exemplifyed by that of Fanwick Epileptick and Hystericall persons. A perpetuall Light.

现进行逐句翻译解释：

"A Ship to Sail without Winds, and A Ship not to be Sunck:" 一艘能在无风的情况下航行、而且不会沉没的船。
"Freedom from Necessity of much Sleeping exemplifyed by Iggorawzon, of Tea and what happens in Mad Men:" 通过Iggorawzon（可能是某人的名字）对茶的效果以及发生在疯子身上的事情例证，表明了人们可以不需要过多睡眠的自由。
"Pleasing Dreams & physicall Exercises exemplifyed by O Egyptian Electuary and by the Fungus mentioned by the French Author:" 通过埃及的药膏和一位法国作家提到的菌类，示例出愉悦的梦境和体育锻炼。
"Great Strength and Agility of Body exemplifyed by that of Franwick Epileptick and Hystericall persons:" 通过Franwick癫痫患者和歇斯底里者展示的例子，说明了身体的巨大力量和敏捷性。
"A perpetuall Light:" 一种永恒的光。
请注意，由于这是手写体且可能存在着笔误或者词语现已不常见或有特殊含义，这段文本的翻译可能需要进一步的文献考证和上下文信息来确定其确切的意思。
</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">比较每个样品中粒子的平均动能。下面的图表显示了在相同的封闭、坚硬的容器中的两个纯气体样品。每个彩色球代表一个气体粒子。两个样本的粒子数相同。哪个样品的温度更高?样品A还是样品B？</div>
                            <div style="border:1px solid #eaeaea;padding:8px 12px;display:flex;align-items: center;font-size:12px;background-color:#fff;white-space: nowrap;overflow: hidden;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;border-radius:6px;">
                              <el-image  src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/39714140626fcfe6347712b06faac7f9.png" /> 
                            </div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">由图表中给出的信息，我们知道气体粒子的平均动能可以通过其动能公式 (KE = 1/2 mv^2) 来计算，其中 m 是粒子的质量，v 是粒子的速度。这里的问题不需要我们具体计算动能的值，因为我们要比较的是两个样本的温度，而在一个理想气体中，粒子的平均动能与温度成正比。

两个样本中粒子的质量都是相同的（32 u），因此它们的平均动能只取决于粒子的平均速度。由于样品A中的平均粒子速度是600 m/s，而样品B中的平均粒子速度是540 m/s，样品A中粒子的平均动能要高于样品B。因此，根据理想气体定律，我们可以得出样品A的温度高于样品B。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">这里有三个子图像排列在一个2乘2的矩阵。首先，看第一栏的两张图片，在左上角和左下角。然后，根据左边的两张图片，找出第一列的图案。接下来，使用找到的图案和右上角的图像来推断缺失的图形。最后，描述一下丢失的图像会是什么样子?</div>
                            <div style="border:1px solid #eaeaea;padding:8px 12px;display:flex;align-items: center;font-size:12px;background-color:#fff;white-space: nowrap;overflow: hidden;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;border-radius:6px;">
                              <el-image  src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/b52e6017d1e92b5ef5f8fdc49f7f1b68.png" /> 
                            </div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">在左上角和左下角的图像中，我们可以看到两个形状：一个是简单的菱形，另一个是菱形中间带有一个圆点。这似乎暗示着左栏的图片是从一个简单形状到一个在该形状中添加了一个特征（这个案例中是圆点）的过程。

然后我们来看右上角的图片，是一个五角星的形状。

根据这一模式，我们可以推断，丢失的图像应该是右上角五角星形状的一个变体，同时在这个基础形状中加入左列图像所展示的递进特征，也就是在中心位置加上一个圆点。

综上所述，缺失的图像应该是一个五角星中带有一个位于中心的圆点。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div>
                    <el-card style="background-color:#f7f8fa;" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">这张图片有什么好笑的？</div>
                            <div style="border:1px solid #eaeaea;padding:8px 12px;display:flex;align-items: center;font-size:12px;background-color:#fff;white-space: nowrap;overflow: hidden;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;border-radius:6px;">
                              <el-image  src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/efabc561e4ca42bb85345e2bde85df2c.png" /> 
                            </div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">这张图片带有幽默因素，因为它展示了一只绘画的犀牛，正在画一幅景色。这幅景色包含了一颗树、太阳和什么看起来像是山的东西。然而，在犀牛的画作中，山看起来非常像犀牛的角，这表明在犀牛的视角，它永远会被自己的角挡住中间视野。这与图片下方的文字 "You see the world as you are!" 相呼应，意为个体的视角、经验和特质很大程度上影响着他们如何感知世界。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>

                    <el-card style="background-color:#f7f8fa;" class="mt-15" shadow="hover">
                      <div>
                        <div class="flex">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/chatgpt.png" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">通言</div>
                            <div style="color:#242424;font-size:13px;line-height:26px;font-weight: 500;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">你可以为我解释这四副图的含义吗？</div>
                            <div style="border:1px solid #eaeaea;padding:8px 12px;display:flex;align-items: center;font-size:12px;background-color:#fff;white-space: nowrap;overflow: hidden;-webkit-line-clamp: 1;-webkit-box-orient: vertical;text-overflow: ellipsis;border-radius:6px;">
                              <el-image  src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/976b3b7ceec9e0f5bc477724e42c0883.png" /> 
                            </div>
                          </div>
                        </div>
                        
                        <div class="flex mt-15">
                          <div><el-avatar :size="26" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/zhi-pu-qing-yan.webp" /></div>
                          <div class="text-left ml-10">
                            <div style="color:#a1a1a1;margin-top:4px;" class="fs-12">智谱AI</div>
                            <div style="color:#141414;font-size:13px;line-height:26px;overflow: hidden;display: -webkit-box;-webkit-line-clamp: 8;-webkit-box-orient: vertical;text-overflow: ellipsis;" class="mt-10">这四副图通过简化的插图和对话泡沫表达了一种关于"认知差异"和"视角"的观点。

第一副图中，三个人物分别有着不同的思维泡沫——分别是方形、圆形、三角形。他们看着天空中对应形状的云彩，第一个人物说："I'm glad we all agree."（我很高兴我们都同意了。）这表示他们都同意他们所看见的形状，但从我们的视角来看，他们实际上看到的是完全不同的东西。

第二副图继续展示天空中的云彩变成了彼此对立的形状，暗示形态的角度不同可能导致不同的理解。在这个时候，第二个人物说：“oh...”（噢……），暗示他意识到他们的观点并不完全一致。

第三副图展示的是夜晚，每个人的头上都有一个不同的思考泡沫，里面分别是不同的星座图形（方形、星形、三角形）。其中一个人说：“ah ha!”（啊哈！），意即他们都看到了不同的星座形状，而此刻他们意识到了这一点。

第四副图表明，当他们都看到相同的思维泡沫内容时——这次是类似齿轮的形状——他们显然再次取得了共识，因此第一个人物重复说：“I'm glad we all agree!”（我很高兴我们都同意了！）。

总的来说，这组图展示了如何从不同的角度看待同一个问题可能导致不同的认知，而在发现这种差异后可能需要通过不同的方法来达到共识。这也可能象征着协作和团队工作中，理解每个成员的观点可能与自己不同，但通过共同努力可以找到共同点。</div>
                          </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;margin-top:10px;">
                          <div class="fs-12 flex flex-center" style="color:#a1a1a1;">
                            <el-icon><ChatLineSquare /></el-icon>
                            <span class="ml-2">共2条</span>
                          </div>
                          <div class="flex">
                            <div class="fs-12 flex flex-center" style="color:#434343;">
                              <el-icon class="color-main"><View /></el-icon>
                              <span class="ml-2">查看全部</span>
                            </div>
                            <div class="fs-12 flex flex-center ml-10" style="color:#434343;">
                              <el-icon class="color-main"><Pointer /></el-icon>
                              <span class="ml-2">我也要问</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </el-card>
                  </div>
                </el-col>
                
              </el-row>
              
            </div>
          </div>
        </el-col>


      </el-row>

    </div>
  </div>
  
</template>

<script>
import AiHeader from '../components/common/AiHeader.vue'

import { ref } from 'vue';
import { useRouter } from 'vue-router';
import {apiCreateTalkRecord} from "@/api/chat/chat";
import {ElMessage} from "element-plus";
export default {
  name: 'AiIndex',
  setup() {
    const isFocused = ref(false);
    const handleFocus = () => {
      isFocused.value = true;
    }
    const handleBlur = () =>{
      isFocused.value = false;
    }

    const engineFlag = ref(3);

    const newMessage = ref('');

    const router = useRouter();
    const sendMessage = () => {
      if (parseInt(localStorage.getItem('is_login')) !== 1) {
        ElMessage({
          message: '请先登录后操作！',
          type: 'warning',
        })
        return;
      }

      if (newMessage.value.trim() !== '') {
        let ai_type_str = 'zhipuai';
        let engine_type = 'zhipuai';
        
        if (engineFlag.value == 2) {
          ai_type_str = 'kimi';
          engine_type = 'kimi';
        }

        if (engineFlag.value == 3) {
          ai_type_str = 'tyqw';
          engine_type = 'tyqw';
        }

        if (engineFlag.value == 4) {
          ai_type_str = 'chatgpt';
          engine_type = 'gpt-4o-mini';
        }

        if (engineFlag.value == 6) {
          ai_type_str = 'deepseek';
          engine_type = 'deepseek-chat';
        }

        //生成新对话框
        let param = {
          engine_type: engine_type,
          talk_content: newMessage.value.trim()
        }
        apiCreateTalkRecord(param).then(res => {
          if (res.data.err_no == 0 && res.data.results != "") {
            const current_text_session_key = "current_text_session_id";
            localStorage.setItem(current_text_session_key, res.data.results);
            router.push({  name: 'Chat',query: { ...router.currentRoute.value.query} });
          }
        })
      }
    }

    const chooseAiEngine = (i) => {
      engineFlag.value = i;
    }

    const mItemIndex = ref(1)
    const selectMItem = (i) => {
      mItemIndex.value = i
    }

    const pMContent = ref({
      "code_develop":[
        {
          "title": '前端开发',
          "desc": '提供项目目标和依赖，输出前端代码',
          "content": '我希望你能担任高级前端开发员。我将描述一个项目的细节，你将用这些工具来编码项目。Create React App, yarn, Ant Design, List, Redux Toolkit, createSlice, thunk, axios. 你应该将文件合并到单一的 index.js 文件中，而不是其他。不要写解释。'
        },
        {
          "title": '全栈程序员',
          "desc": '从前后端全面思考，提供部署策略',
          "content": '我希望你能扮演一个软件开发者的角色。我将提供一些关于网络应用需求的具体信息，而你的工作是提出一个架构和代码，用 Golang 和 Angular 开发安全的应用。'
        },
      ],
      "language_study": [
        {
          "title": '英语练习',
          "desc": '英语对话交谈,回复会限制在100字以内',
          "content": '我希望你能充当英语口语老师和提高者。我将用英语与你交谈，而你将用英语回答我，以练习我的英语口语。我希望你能保持回复的整洁，将回复限制在 100 字以内。我希望你能严格纠正我的语法错误、错别字和事实性错误。我希望你在回答中向我提出一个问题。现在我们开始练习，你可以先问我一个问题。记住，我要你严格纠正我的语法错误、错别字和事实性错误。'
        },
        {
          "title": '语法对照检查',
          "desc": '检查语法问题',
          "content": '你能帮我确保语法和拼写的正确性吗？如果你发现语法或拼写错误，请将你发现的错误列在一个两栏的标记表中，将原文放在第一栏，将更正后的文本放在第二栏，并将你修正的关键词用黑体字标出'
        },
        {
          "title": '单词联想记忆助手',
          "desc": '场景化记忆单词',
          "content": '我要你充当记忆大师，我给你单词，你要充分利用部分谐音记忆（可以用部分音节谐音），字体联想记忆，动态字母记忆，图像场景记忆，也可以联想到简单的类似单词，帮我在英文单词和中文解释之间搭建好桥梁、也就是插入一个第三方，要求我激活我的大脑，让它足够发散，足够思考，构建一个具体的、超现实的、有情感的场景，也翻译成中文，这里有一个构建样本：当然，让我根据「梁」这个词为你创造一个想象的记忆。想象一下，你站在一座高耸的灯塔外，身后是绵延的大海。上面的天空多云，每隔几秒钟就有一道闪电照亮风景。突然，一道强大的光束从灯塔顶部射出，划破黑暗，向水面投下一个明亮的白色光圈。你可以看到光线在海浪中扩散开来，照亮了沿途的一切，并将阴影推回。当你观看时，这束光开始闪烁和跳舞，随着上面风暴的节奏变化。这束光似乎是活的，带着能量的脉动和悸动。你能感觉到光束穿透了它们所接触到的一切，使你从头到脚都充满了力量感和震撼力。随着这种强大而有活力的光束的生动形象在你的脑海中播放，你将能够以一种生动和难忘的方式记住「光束」的定义。闪电、水和灯塔的光束的组合将帮助你以一种具体而非凡的方式来想象和记忆这个词。'
        },
        {
          "title": '语言学习挑战',
          "desc": '设计学习挑战',
          "content": '你是一个语言学习挑战的设计者，能够设计各种学习挑战来提升学习者的语言能力，现请生成10个语言学习的挑战任务'
        },
        {
          "title": '在线口语考试参与者',
          "desc": '提供在线口语考试环境',
          "content": '你是一个在线口语考试的参与者，能够在真实环境中进行口语表达和评估，现请准备就以下题目进行口语表达，题目：The Power of Smiles，要求：不超过800字'
        },
        {
          "title": '学习资源提供者',
          "desc": '提供学习资料',
          "content": '你是一个学习资源提供者，能够分享学习资料，现请提供一篇关于语法学习的教材。要求：1.要包含各种时态的使用方法；2.使用事例进行说明；3.分点叙述。'
        },

        {
          "title": '语言学习游戏',
          "desc": '通过游戏提升语言能力',
          "content": '你是一个语言学习游戏的玩家，能够通过游戏提升语言能力，现请玩一局英语单词拼图游戏。'
        },

        {
          "title": '语言学习社区',
          "desc": '分享学习经验',
          "content": '你是一个语言学习社区的成员，喜欢与其他学习者交流并分享学习经验，现请写一篇关于你最近关于记单词的学习心得的帖子。'
        },

        {
          "title": '阅读指导员',
          "desc": '阅读理解训练',
          "content": '你是一个阅读指导员，能够提供阅读材料和答案解析，现请你阅读以下文章，并回答以下问题。文章：“”“小提琴声不绝如缕，低回倾诉，使人悠悠然于心旌神摇中不知不觉地随它步入一片宁谧澄静的天地，而且深深地陶醉了。”“”，问题：“”“作者此时的情感色彩是怎样的？”“”。'
        },

      ]
    })

    const setSendMessage = (msg) => {
      newMessage.value = msg
    }

    const pmiFlag = ref(1)
    const choosePmi = (i) => {
      pmiFlag.value = i
    }

    const fileParseJump = () => {
      let ai_type_str = '';
      if (engineFlag.value == 3) {//
        ai_type_str = 'tyqw';
      }
      
      ai_type_str = 'tyqw';//目前仅支持通义千问文件解析
      router.push({  name: 'Chat',query: { ...router.currentRoute.value.query, ai_type: ai_type_str} });
    }
    

    return {
      newMessage,
      isFocused,
      engineFlag,
      mItemIndex,
      pMContent,
      pmiFlag,
      handleFocus,
      handleBlur,
      sendMessage,
      chooseAiEngine,
      selectMItem,
      setSendMessage,
      choosePmi,
      fileParseJump
    };
  },
  components: {
    AiHeader
  },
}
</script>

<style scoped>
#ai-index {
  min-height: 100vh;
  width:100%;
  background-color:#f5f7ff;
}

.ai-body {
  width:1120px;
  margin:0 auto;
}


.ai-title-info {
  height:44px;
  padding:0 14px;
  font-size: 16px;
  background-color:rgba(255,255,255,.5);
  border-radius:8px;
}
.ai-title-img {
  width:20px;
  height:20px;
  border-radius:6px;
}

.object-active {
  background-color: #000 !important;
  color:#fff !important;
}

.search-input {
  border-radius:8px;
  background-color:#fff;
  padding:10px 20px;
  width:925px;
  margin:30px auto;
  box-shadow: 5px 4px 6px #edf3ff, 5px 8px 12px #edf3ff;
  border: 1px solid transparent;
}

.search-input.focused {
  border-color: #2775ff;
}
.m-item-list, .m-item-content {
  border-radius:8px;
  background-color:#fff;
  box-shadow: 5px 4px 6px #edf3ff, 5px 8px 12px #edf3ff;
}
.m-item {
  height:66px;
  border-bottom:1px solid #efefef;
}
.m-item-icon {
  background-color:#edf3ff;
  border-radius:6px;
  width:30px;
  height:30px;
}
.m-item-title {
  font-weight: 700; 
  font-size: 13px;
  color: #3b4250;
  line-height: 18px;
}
.m-item-desc {
  font-weight: 400;
  font-size: 12px;
  color: #ababab;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.m-item-active {
  background-color: #2775ff;
  border-radius:8px;
}
.m-item-active .m-item-desc, 
.m-item-active .m-item-title,
.m-item-active .m-item-icon{
  color:#fff !important;
}
.m-item-active .m-item-icon {
  background-color:#edf3ff38;
}

.m-item-content {
  padding:12px 24px;
  min-height:120px;
}

.hot-talk-content-active {
  font-weight:500;
  color:#434343;
}
.other-ai:hover .arrow-change {
  /* 旋转图片 180 度 */
  transform: rotate(180deg);
  /* 可选：设置旋转中心 */
  transform-origin: center;
}

.p-m-list {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding:24px;
  height:185px;
  box-sizing: border-box;
  margin-bottom:24px;
}
.p-m-detail {
  color:#000;
  word-break: break-all;
  height: 60px;
  line-height: 20px;
  overflow-y: auto;
}

.hide-item-engin {
  width:210px;
  margin:0 auto;
  display: flex;
  align-items:center;
  color:#606266;
}
</style>
