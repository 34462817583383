
<template>
  <el-aside width="18vw"  :class="['left-header', { collapsed }]">
    <div style="padding:0 13px;background: linear-gradient(to bottom, #a8eaf052, #fff);" class="flex space-between flex-align-center" v-show="!collapsed">
      <router-link to="/">
        <el-image  src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/ty-logo.png" style="width:90px;"/>
      </router-link>

      <span @click="toggleSidebar" class="cursor-pointer"><el-icon class="fs-20"><Fold /></el-icon></span>
    </div>
    <div v-show="collapsed" class="mt-10" >
      <span @click="toggleSidebar" class="cursor-pointer"><el-icon class="fs-20"><Expand /></el-icon></span>
    </div>
    <div class="subject-container">
      <div  class="module-info">
        <router-link to="/">
          <div class="text-left column-module">
            <img src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/smart-optimization.png" alt="" style="width:30px;height:30px;">
<!--            <el-avatar src="" style="width:30px;height:30px;"/>-->
            <span class="module-title" v-show="!collapsed">DS-R1</span>
          </div>
        </router-link>
      </div>
    </div>
    <div class="scroll-container">
      <div class="module-info">
        <router-link to="/elevator">
          <div class="text-left column-module">
            <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/logo-elevator.png" style="width:30px;height:30px;"/>
            <span class="module-title" v-show="!collapsed">电梯板块</span>
          </div>
        </router-link>
      </div>
      <div class="module-info">
        <router-link to="/travel">
          <div class="text-left column-module">
            <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/travel_logo.png" style="width:30px;height:30px;"/>
            <span class="module-title" v-show="!collapsed">旅行推荐官</span>
          </div>
        </router-link>
      </div>
      <div class="module-info">
        <router-link to="/english">
          <div class="text-left column-module">
            <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/%E9%80%9A%E8%A8%80%E8%8B%B1%E8%AF%AD%E8%AE%AD%E7%BB%83.jpg" style="width:30px;height:30px;"/>
            <span class="module-title" v-show="!collapsed">英语训练师</span>
          </div>
        </router-link>
      </div>
      <div class="module-info">
        <router-link to="/news">
          <div class="text-left column-module">
            <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/%E6%97%B6%E4%BA%8B%E7%83%AD%E7%82%B9%E7%9C%BC.jpeg" style="width:30px;height:30px;"/>
            <span class="module-title" v-show="!collapsed">时事热点眼</span>
          </div>
        </router-link>
      </div>
      <div class="module-info">
        <router-link to="/industry">
          <div class="text-left column-module">
            <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/3771774579254700_1741329057777154739.jpeg" style="width:30px;height:30px;"/>
            <span class="module-title" v-show="!collapsed">行业分析师</span>
          </div>
        </router-link>
      </div>
      <div class="module-info">
        <router-link to="/labReport">
          <div class="text-left column-module">
            <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/syszk.jpeg" style="width:30px;height:30px;"/>
            <span class="module-title" v-show="!collapsed">实验报告生成器</span>
          </div>
        </router-link>
      </div>


      <div v-show="!collapsed && pOne.data !== undefined &&pOne.data.length>0">
        <div class="text-left" style="padding:0px 8px;">
          <h3 class="chat-date">{{ pOne.title }}</h3>
        </div>
        <div :class="['chat-title', selectedSessionId==item.session_id?'chat-title-selected':'']" v-for="item in pOne.data" @click="selectChatTitle(item.session_id, item.engine_type)">
          <div class="chat-title-content" >{{item.talk_title}}</div>
          <el-popover placement="bottom"  trigger="hover" >
            <template #reference>
              <el-icon class="chat-operation"><MoreFilled /></el-icon>
            </template>
            <template #default>
              <ul style="text-align: center;">
                <li class="flex flex-center title-operate-li fs-12" @click="deleteChatInfo(item.session_id)">
                  <el-icon><Delete /></el-icon>
                  <span class="ml-10">删除</span>
                </li>
              </ul>
            </template>
          </el-popover>
        </div>
      </div>

      <div v-show="!collapsed && pTwo.data !== undefined &&pTwo.data.length>0">
        <div class="text-left" style="padding:0px 8px;">
          <h3 class="chat-date">{{ pTwo.title }}</h3>
        </div>
        <div @click="selectChatTitle(item.session_id, item.engine_type)" :class="['chat-title', selectedSessionId==item.session_id?'chat-title-selected':'']" v-for="item in pTwo.data" >
          <div class="chat-title-content" >{{item.talk_title}}</div>
          <el-popover placement="bottom"  trigger="hover" >
            <template #reference>
              <el-icon class="chat-operation"><MoreFilled /></el-icon>
            </template>
            <template #default>
              <ul style="text-align: center;">
                <li class="flex flex-center title-operate-li fs-12" @click="deleteChatInfo(item.session_id)">
                  <el-icon><Delete /></el-icon>
                  <span class="ml-10">删除</span>
                </li>
              </ul>
            </template>
          </el-popover>
        </div>
      </div>

      <div v-show="!collapsed && pThree.data !== undefined &&pThree.data.length>0">
        <div class="text-left" style="padding:0px 8px;">
          <h3 class="chat-date">{{ pThree.title }}</h3>
        </div>
        <div @click="selectChatTitle(item.session_id, item.engine_type)" :class="['chat-title', selectedSessionId==item.session_id?'chat-title-selected':'']" v-for="item in pThree.data" >
          <div class="chat-title-content" >{{item.talk_title}}</div>
          <el-popover placement="bottom"  trigger="hover" >
            <template #reference>
              <el-icon class="chat-operation"><MoreFilled /></el-icon>
            </template>
            <template #default>
              <ul style="text-align: center;">
                <li class="flex flex-center title-operate-li fs-12" @click="deleteChatInfo(item.session_id)">
                  <el-icon><Delete /></el-icon>
                  <span class="ml-10">删除</span>
                </li>
              </ul>
            </template>
          </el-popover>
        </div>
      </div>
    </div>
    <div style="padding: 0px 8px">
      <a href="/serviceCenter">
        <div style="padding:4px 8px;" class="service-center flex flex-align-center cursor-pointer">
            <div><img style="width:20px;height:20px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/znt-more-app.png" alt=""></div>
            <div v-show="!collapsed" class="fw-600 fs-16 ml-10 color-title" style="line-height:16px;">业务展示中心</div>
        </div>
      </a>
      <div style="padding:6px;" v-show="!collapsed"><img style="width:100%;border-radius:8px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/deepseek-free.png" alt=""></div>
    </div>
  </el-aside>

  <el-dialog v-model="deleteChatDialogVisible" title="对话删除操作" width="400" >
    <div style="margin:20px 0px;" class="flex flex-center">
      <el-icon class="color-danger"><WarningFilled /></el-icon>
      <span class="ml-10">确定要删除该对话吗</span>
    </div>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="deleteChatDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmDeleteChatInfo">
          确认
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>
<script setup>
import {apiGetUserTextChatList,apiDeleteChatInfo} from "@/api/chat/chat";
import {onMounted, ref} from "vue";
import {ElMessage} from "element-plus";
import router from "../../router";
import {Expand, Fold} from "@element-plus/icons-vue";



const selectedSessionId = ref("")
const pOne = ref({})
const pTwo = ref({})
const pThree = ref({})
const deleteSessionId = ref("")
const deleteChatDialogVisible = ref(false)
onMounted(() => {
  getUserTextChatList();
});
const collapsed = ref(false) // 控制是否折叠
const toggleSidebar = () => {
  collapsed.value = !collapsed.value
}

const getUserTextChatList = () => {
  apiGetUserTextChatList().then(res => {
    if (res.data.err_no === 0) {
      let data = res.data.results;
      pOne.value = data.pOne
      pTwo.value = data.pTwo
      pThree.value = data.pThree
    }
  });
}

const selectChatTitle = (sessionId, aiType) => {
  selectedSessionId.value = sessionId;

  const current_text_session_key = "current_text_session_id";
  localStorage.setItem(current_text_session_key, sessionId);
  router.push({  name: 'Chat',query: { ...router.currentRoute.value.query,ai_type: aiType,session_id: selectedSessionId.value}});
}

const deleteChatInfo = (sessionId) => {
  deleteSessionId.value = sessionId;
  deleteChatDialogVisible.value = true;
}

const confirmDeleteChatInfo = () => {
  apiDeleteChatInfo({session_id: deleteSessionId.value}).then(res => {
    if (res.data.err_no == 0) {
      deleteSessionId.value = "";
      deleteChatDialogVisible.value = false;
      ElMessage({
        message: '操作成功！',
        type: 'success',
      })
      getUserTextChatList();
    } else {
      ElMessage({
        showClose: true,
        message: '操作失败了！',
        type: 'error',
      })
    }
  })
}

</script>
<style scoped>
.left-header {
  border-right: 1px solid #eeeeee;
  overflow: hidden;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

/* 收缩时 */
.left-header.collapsed {
  width: 50px;
}

.scroll-container {
  padding:0 13px;
  //height:70vh;
  overflow: auto;
  flex: 1;
}
.subject-container {
  padding:0 13px;
}
.left-header.collapsed .scroll-container {
  padding:0;
}
.left-header.collapsed .subject-container {
  padding:0;
}

.module-info {
  margin-top:6px;
  display: flex;
  align-items: center;
  padding: 6px 0px;
}
.module-title {
  color:#1a2029;font-weight:500;font-size:13px;margin-left:12px;
}
.column-module {
  border-radius: 6px;
  padding:0px 8px;
  display:flex;
  align-items: center;
  color:#333;
}
.column-module h3 {
  margin-left:10px;
  font-weight: 600;
  font-size: 14px;
}
.module-info:hover {
  border-radius:8px;
  cursor: pointer;
  background-color:#f6f7f9;
}

.service-center:hover {
  border-radius:8px;
  cursor: pointer;
  background-color:#f6f7f9;
}

.chat-date {
  color:#7d7d7d;
  font-weight: 600;
  font-size: .75rem;
  line-height: 1rem;
}
.chat-title {
  position: relative;
  text-align:left;
  color:#0d0d0d;
  font-size:12px;
  padding: 12px;
  cursor: pointer;
}

.chat-title:hover {
  background-color:#4F586614;
  border-radius:6px;
  font-weight: 600;
}

.chat-operation {
  display: none;
  color:#909399;
  position: absolute; /* 绝对定位 */
  right: 10px;
  top:13px;
}

.chat-title:hover .chat-operation {
  display: block; /* 鼠标悬停时显示 */
}

.chat-title-content {
  width:88%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chat-title-selected {
  border-radius:6px;
  background-color:#ececec;
}
.title-operate-li {
  padding:6px 0px;
}
.title-operate-li:hover {
  pointer-events: auto;
  background-color:#ececec;
  border-radius:6px;
  cursor: pointer;
}

ul, ol {
  list-style: none; /* 移除列表样式 */
  padding: 0;       /* 可选：移除内边距 */
  margin: 0;        /* 可选：移除外边距 */
}
</style>
<style>
.el-dialog__header {
  text-align: left;
}
</style>