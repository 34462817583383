<script setup>

</script>

<template>
<div style="padding:20px;box-sizing: border-box;height:100vh;background-color: #f6f7f9;width:100%;">
  <div><h1>业务展示中心</h1></div>
  <div>
    <router-link to="/aiset">
      <div class="flex" style="padding:20px;background-color: #fff;border-radius:8px;cursor: pointer;">
        <div>
          <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/icon/ai-collect.png" style="width:60px;height:60px;"/>
        </div>
        <div class="ml-16 text-left">
          <div class="fw-600 fs-18 color-title">精选AI产品集 —— 高效工具，让创作更智能！</div>
          <div class="mt-10 color-normal">💡 本合集汇总了当前最热门的 AI 工具，涵盖 AI 聊天、图片生成、视频编辑、语音合成 等多个领域，助力你的创作和工作效率提升！ 🚀</div>
        </div>
      </div>
    </router-link>

    <router-link to="/es">
      <div class="flex mt-20" style="padding:20px;background-color: #fff;border-radius:8px;cursor: pointer;">
        <div>
          <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/YPPc8iIddkgDb69o-generated_image.jpg" style="width:60px;height:60px;"/>
        </div>
        <div class="ml-16 text-left">
          <div class="fw-600 fs-18 color-title">企业服务 —— 数字化赋能，助力企业智能升级！</div>
          <div class="mt-10 color-normal">💡 本合集涵盖 数字化管理、云计算与大数据、人工智能、软件开发、产品管理、项目管理、信息安全、微软技术 等核心课程与工具，帮助企业提升运营效率、优化业务流程，实现智能化转型！ 🚀</div>
        </div>
      </div>
    </router-link>

    <router-link to="/bs">
      <div class="flex mt-20" style="padding:20px;background-color: #fff;border-radius:8px;cursor: pointer;">
        <div>
          <el-avatar src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/rsiBziki63Iyu1Mt-generated_image.jpg" style="width:60px;height:60px;"/>
        </div>
        <div class="ml-16 text-left">
          <div class="fw-600 fs-18 color-title">企业服务 —— 数字化赋能，构建智慧产业生态！</div>
          <div class="mt-10 color-normal">💡 业务涵盖 智慧车运、智慧垃圾分类、智慧冷链集配、智慧冷链充冷、智慧光伏、智慧风场 等多个领域，利用 大数据、人工智能、物联网（IoT） 等技术，助力企业实现智能化管理、降本增效，推动绿色可持续发展！ 🚀</div>
        </div>
      </div>
    </router-link>
  </div>
</div>
</template>

<style scoped>

</style>