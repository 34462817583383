<template>
  <el-container class="common-layout">
<!--    <el-aside width="17vw" style="background-color:#fff;color:#333;"  v-if="!fullScreen">-->
<!--      <Header />-->
<!--    </el-aside>-->
    <ChatHeader />
    <router-view></router-view>
  </el-container>
</template>

<script>
import '@/assets/css/common.css';
import { ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import ChatHeader from "./components/common/ChatHeader.vue";

// import Header from '@/components/common/Header.vue'
export default {
  name: 'App',
  components: {
    ChatHeader
    // Header
    // Body,
    // Foot
  },
  setup() {
    
    const route = useRoute();
    const currentRoutePath = ref(route.path);
    const fullScreen = ref(false);
    watch(route, (newRoute) => {
      currentRoutePath.value = newRoute.path;
      if (currentRoutePath.value == '/agreement' || currentRoutePath.value == '/private') {
        fullScreen.value = true;
      }
    });

    return {
      currentRoutePath,
      fullScreen
    };
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  min-height: 100vh;
}

.common-layout {
  height: 100vh;
}

body {
  margin: 0 !important;
  padding: 0 !important;
  height: 100vh;
    overflow: hidden;
}
</style>
