<template>
  <div id="EnterpriseServices">
    <div style="margin:40px 0 0 40px;text-align:left;">
      <h1 class="set-title">企业服务</h1>
    </div>

    <div style="display:flex;  justify-content: space-between;">
      <div class="ai-module">
        <div class="ai-title">
          <el-divider direction="vertical" style="border-left:3px solid #2775ff;"/>
          <span>数字化管理课程</span>
        </div>
        <div style="padding-left:10px;">
          <div class="fs-14 mt-10">数字化IT服务管理（ITIL4 Foundation）认证</div>
          <div class="fs-14 mt-10">ITIL4 Specialist Create,Delivery & Support-CDS（创建，交付和支持)</div>
          <div class="fs-14 mt-10">ITIL4 Specialist Drive Stakeholder Value -DSV(驱动干系人价值）</div>
          <div class="fs-14 mt-10">ITIL4 Specialist High Velocity IT-HVIT (高速IT）</div>
          <div class="fs-14 mt-10">ITIL4 Specialist Direct, Plan & Improve-DPI（指引，计划和优化）</div>
          <div class="fs-14 mt-10">ITIL4 Specialist Digital & IT Strategy -DITS (数字化和IT战略）</div>
          <div class="fs-14 mt-10">ITIL4 Practice Manger（实践经理）</div>
          <div class="fs-14 mt-10">CDMP数据治理专业人士认证</div>
          <div class="fs-14 mt-10">CDGA数据管理专业人士认证</div>
          <div class="fs-14 mt-10">企业大数据专家EBDP认证</div>
          <div class="fs-14 mt-10">认证数据中心专家（CDCP）基础认证班</div>
          <div class="fs-14 mt-10">DevOps Foundation认证</div>
          <div class="fs-14 mt-10">DevOps Master认证</div>
        </div>
      </div>

      <div class="ai-module" style="">
        <div class="ai-title">
          <el-divider direction="vertical" style="border-left:3px solid #2775ff;"/>
          <span>云与大数椐课程</span>
        </div>
        <div style="padding-left:10px;">
          <div class="fs-14 mt-10">AWS亚马逊云系统架构和实施培训 </div>
          <div class="fs-14 mt-10">AWS亚马逊云高级架构认证培训</div>
          <div class="fs-14 mt-10">阿里云ACP认证工程师</div>
          <div class="fs-14 mt-10">Hadoop 大数据管理培训</div>
          <div class="fs-14 mt-10">Python分析与数据挖掘课程</div>
          <div class="fs-14 mt-10">OpenStack 云计算系统架构师-初中级</div>
          <div class="fs-14 mt-10">SPSS Statistics高级实战应用培训</div>
          <div class="fs-14 mt-10">企业级Kubernetes和Docker容器技术最佳实践 </div>
        </div>
      </div>

    </div>

    <div style="display:flex;justify-content: space-between;margin-top:40px;">
      <div class="ai-module">
        <div class="ai-title">
          <el-divider direction="vertical" style="border-left:3px solid #2775ff;"/>
          <span>人工智能课程</span>
        </div>
        <div style="padding-left:10px;">
          <div class="fs-14 mt-10">人工智能/机器学习+数据挖掘+视觉处理</div>
          <div class="fs-14 mt-10">人工智能-知识图谱应用技术实战</div>
          <div class="fs-14 mt-10">计算机图像（视觉）处理</div>
          <div class="fs-14 mt-10">ChatGPT人工智能生产力应用</div>
          <div class="fs-14 mt-10">AI深度学习和自然语言（NLP）处理</div>
        </div>
      </div>

      <div class="ai-module" style="">
        <div class="ai-title">
          <el-divider direction="vertical" style="border-left:3px solid #2775ff;"/>
          <span>软件开发课程</span>
        </div>
        <div style="padding-left:10px;">
          <div class="fs-14 mt-10">Python 自动化办公最佳实践</div>
          <div class="fs-14 mt-10">软件架构实战</div>
          <div class="fs-14 mt-10">互联网前端开发班（H5+JS)</div>
          <div class="fs-14 mt-10">软件测试ISTQB</div>
          <div class="fs-14 mt-10">开发团队管理-用TFS建立企业团队开发环境</div>
          <div class="fs-14 mt-10">软件质量管理</div>
          <div class="fs-14 mt-10">RPA机器人流程自动化</div>
        </div>
      </div>

    </div>

    <div style="display:flex;justify-content: space-between;margin-top:40px;">
      <div class="ai-module">
        <div class="ai-title">
          <el-divider direction="vertical" style="border-left:3px solid #2775ff;"/>
          <span>项目管理课程</span>
        </div>
        <div style="padding-left:10px;">
          <div class="fs-14 mt-10">PMP 项目管理认证（面授/在线）</div>
          <div class="fs-14 mt-10">PGMP 项目集认证（全球招生）</div>
          <div class="fs-14 mt-10">PRINCE2®Foundation 项目管理认证（2017新版）</div>
          <div class="fs-14 mt-10">PRINCE2®Practitioner 项目管理从业者级认证（2017新版）</div>
          <div class="fs-14 mt-10">PMI-PBA 商业分析师认证</div>
          <div class="fs-14 mt-10">国家软考-信息系统集成项目管理工程师（中项）</div>
          <div class="fs-14 mt-10">国家软考-信息系统集成项目管理管理师（高项）</div>
          <div class="fs-14 mt-10">项目管理定制培训</div>
        </div>
      </div>

      <div class="ai-module">
        <div class="ai-title">
          <el-divider direction="vertical" style="border-left:3px solid #2775ff;"/>
          <span>产品经理课程</span>
        </div>
        <div style="padding-left:10px;">
          <div class="fs-14 mt-10">NPDP国际产品经理认证</div>
          <div class="fs-14 mt-10">CSPO产品负责人</div>
          <div class="fs-14 mt-10">产品经理内训</div>
        </div>
      </div>

    </div>

    <div style="display:flex;justify-content: space-between;margin-top:40px;">
      <div class="ai-module">
        <div class="ai-title">
          <el-divider direction="vertical" style="border-left:3px solid #2775ff;"/>
          <span>微软系列课程</span>
        </div>
        <div style="padding-left:10px;">
          <div class="fs-14 mt-10">MCSE /Windows Server2019</div>
          <div class="fs-14 mt-10">Windows Server2019混合管理</div>
          <div class="fs-14 mt-10">Windows Server2022新特性实战</div>
          <div class="fs-14 mt-10">Power BI 数据分析和可视化最佳实践</div>
          <div class="fs-14 mt-10">微软云Azure基础结构解决方案和运维</div>
          <div class="fs-14 mt-10">部署和规划OFFICE365</div>
          <div class="fs-14 mt-10">SharePoint/Teams管理实战应用培训</div>
          <div class="fs-14 mt-10">SharePoint开发实战应用培训</div>
          <div class="fs-14 mt-10">PowerShell 自动化运维最佳实践</div>
          <div class="fs-14 mt-10">SQL Server 管理培训</div>
          <div class="fs-14 mt-10">SQL Server 开发培训</div>
          <div class="fs-14 mt-10">Azure AD标识和访问管理</div>
          <div class="fs-14 mt-10">Azure数据库混合管理培训</div>
          <div class="fs-14 mt-10">Windows 10/11桌面支持专家</div>
          <div class="fs-14 mt-10">微软RPA应用实践</div>
          <div class="fs-14 mt-10">Power Platform 功能管理实战</div>
          <div class="fs-14 mt-10">Power Platform 开发实战</div>
          <div class="fs-14 mt-10">微软云Azure虚拟桌面</div>
          <div class="fs-14 mt-10">规划、部署和管理SCCM2012</div>
          <div class="fs-14 mt-10">Power Automate应用最佳实践</div>
          <div class="fs-14 mt-10">Power Apps业务应用制作最佳实践</div>
        </div>
      </div>

      <div class="ai-module" style="">
        <div class="ai-title">
          <el-divider direction="vertical" style="border-left:3px solid #2775ff;"/>
          <span>信息安全课程</span>
        </div>
        <div style="padding-left:10px;">
          <div class="fs-14 mt-10">CISSP（国际信息系统安全专家）</div>
          <div class="fs-14 mt-10">CISA（国际信息系统审计专家）</div>
          <div class="fs-14 mt-10">CCSK 国际注册云安全认证</div>
          <div class="fs-14 mt-10">CCRC-PIP 个人信息保护专业人员</div>
          <div class="fs-14 mt-10">CCSSP 国际注册云安全系统专家</div>
          <div class="fs-14 mt-10">CCSP 国际注册云安全专家认证</div>
          <div class="fs-14 mt-10">ISO27001 Foundation认证</div>
          <div class="fs-14 mt-10">ISO27001LA 国际信息安全管理体系主任审核员</div>
          <div class="fs-14 mt-10">等级保护2.0解读及合规（企业内训）</div>
          <div class="fs-14 mt-10">CZTP零信任认证</div>
          <div class="fs-14 mt-10">CISP认证（国家注册信息安全专业人员认证课程）</div>
          <div class="fs-14 mt-10">DPO数据隐私保护官（PDPF、PDPP、ISO27001）</div>
          <div class="fs-14 mt-10"> GDPR全球数据保护法规认证专家 </div>
          <div class="fs-14 mt-10">CBCP认证(国际业务连续性专家）</div>
          <div class="fs-14 mt-10">信息安全技术专家Security+认证</div>
          <div class="fs-14 mt-10">CRISC风险管理</div>
          <div class="fs-14 mt-10">信息安全意识教育培训（定制内训）</div>
        </div>
      </div>

    </div>

    <div style="display:flex;justify-content: space-between;margin-top:40px;">
      <div class="ai-module">
        <div class="ai-title">
          <el-divider direction="vertical" style="border-left:3px solid #2775ff;"/>
          <span>敏捷认证课程</span>
        </div>
        <div style="padding-left:10px;">
          <div class="fs-14 mt-10">Scrum Master基础认证（CSM）</div>
          <div class="fs-14 mt-10">敏捷开发（企业内训）</div>
          <div class="fs-14 mt-10">PMI-ACP敏捷项目管理认证</div>
        </div>
      </div>

      <div class="ai-module" style="">
        <div class="ai-title">
          <el-divider direction="vertical" style="border-left:3px solid #2775ff;"/>
          <span>虚拟化课程</span>
        </div>
        <div style="padding-left:10px;">
          <div class="fs-14 mt-10">VCP 8.0 Vmware vSphere:安装、配置和管理</div>
          <div class="fs-14 mt-10">VM Desktop Management</div>
          <div class="fs-14 mt-10">VM VIEW-- Horizon 安装、配置、管理</div>
          <div class="fs-14 mt-10">Redhat OpenShift容器管理</div>
          <div class="fs-14 mt-10">Kubernetes的Cloud Native云原生实战</div>
        </div>
      </div>

    </div>

    <div style="display:flex;justify-content: space-between;margin-top:40px;">
      <div class="ai-module">
        <div class="ai-title">
          <el-divider direction="vertical" style="border-left:3px solid #2775ff;"/>
          <span>网络系统数据库课程</span>
        </div>
        <div style="padding-left:10px;">
          <div class="fs-14 mt-10">红帽认证工程师Redhat Linux AS 8/ RHCE 8</div>
          <div class="fs-14 mt-10">CCNA/CCNP/CCIE</div>
          <div class="fs-14 mt-10">华为HCIA/HCIP 数通/RS</div>
          <div class="fs-14 mt-10">华为鸿蒙开发HCIA  </div>
          <div class="fs-14 mt-10">Oracle1 12/19C OCP  WDP认证班</div>
          <div class="fs-14 mt-10">Oracle 项目实战+性能调优</div>
          <div class="fs-14 mt-10">MYSQL 管理实战</div>
          <div class="fs-14 mt-10">MONGODB管理实战</div>
        </div>
      </div>

      <div class="ai-module">
        <div class="ai-title">
          <el-divider direction="vertical" style="border-left:3px solid #2775ff;"/>
          <span>CIO课程</span>
        </div>
        <div style="padding-left:10px;">
          <div class="fs-14 mt-10">COBIT ® IT治理专家认证</div>
          <div class="fs-14 mt-10">TOGAF9.2 数字化转型顶层设计与企业架构</div>
          <div class="fs-14 mt-10">CBAP 业务分析专家课程</div>
          <div class="fs-14 mt-10">AI人工智能技术及应用</div>
          <div class="fs-14 mt-10">云计算、超融合与前沿技术领域探讨</div>
          <div class="fs-14 mt-10">微服务架构设计与最佳实践</div>
          <div class="fs-14 mt-10">数字化时代服务管理 VeriSM™  Foundation认证</div>
        </div>
      </div>

    </div>

    
    <div style="display:flex;justify-content: space-between;margin-top:40px;">
      <div class="ai-module">
        <div class="ai-title">
          <el-divider direction="vertical" style="border-left:3px solid #2775ff;"/>
          <span>Office商务应用课程</span>
        </div>
        <div style="padding-left:10px;">
          <div class="fs-14 mt-10">Office智能密钥：Word-Excel-PPT-Outlook（企业内训）</div>
          <div class="fs-14 mt-10">WPS实战</div>
          <div class="fs-14 mt-10">PowerBI Desktop数据分析与呈现</div>
          <div class="fs-14 mt-10">Office 365 云端协作最佳实践</div>
          <div class="fs-14 mt-10">苹果MAC OFFICE实战</div>
          <div class="fs-14 mt-10">MS Project Online高级实战应用 </div>
          <div class="fs-14 mt-10">Xmind思维导图企业应用</div>
          <div class="fs-14 mt-10">Excel 在项目管理中的高效应用</div>
          <div class="fs-14 mt-10">PROJECT在项目管理中的高效应用研修</div>
          <div class="fs-14 mt-10">Power Point商业演讲研修班</div>
          <div class="fs-14 mt-10">PPT 逻辑写作和设计</div>
          <div class="fs-14 mt-10">Photoshop平面设计实战</div>
          <div class="fs-14 mt-10">illustrator高级设计实战</div>
          <div class="fs-14 mt-10">Premiere视频剪辑高级实战</div>
          <div class="fs-14 mt-10">新媒体直播运营</div>
          <div class="fs-14 mt-10">玩赚短视频运营(抖音篇)</div>
          <div class="fs-14 mt-10">短视频制作和编辑最佳实践</div>
        </div>
      </div>
    </div>

    <div style="margin:40px;"> 
      <div>
        <h1 >合作客户</h1>
      </div>
      <div style="display:flex;justify-content: space-between;background-color:#fff;padding:10px;">
        <el-image style="width:90px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/kh-aly.png" />
        <el-image style="width:90px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/kh-bd.png" />
        <el-image style="width:90px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/kh-last.png" />
        <el-image style="width:90px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/kh-wr.png" />
        <el-image style="width:90px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/kh-sap.png" />
        <el-image style="width:90px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/kh-bosch.png" />
      </div>
      <div style="display:flex;justify-content: space-between;align-items:center;background-color:#fff;padding:10px;">
        <el-image style="width:90px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/kh-dz.png" />
        <el-image style="width:90px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/kh-hw.png" />
        <el-image style="width:70px;height:60px" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/kh-bsj.png" />
        <el-image style="width:90px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/kh-xmz.png" />
        <el-image style="width:90px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/kh-ibm.png" />
        <el-image style="width:90px;" src="https://crrc-zctd.oss-cn-shanghai.aliyuncs.com/ai/images/kh-zgmsyh.png" />
      </div>
    </div>
    
  </div>

  
</template>
<script>
export default {
  name: 'EnterpriseServices',
  setup() {}
}
</script>
<style scoped>
#EnterpriseServices {
  min-height: 100vh;
  width:100%;
  overflow: auto;
}
.set-title {
  font-weight: 700;
  font-size: 2.25rem;
  line-height: 2.5rem;
}
.ai-title {
  display:flex;
  align-items:center;
  font-weight: 400;
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.ai-module {
  margin-left:40px;
  margin-right:20px;
  text-align:left;
  background-color:#fff;
  border-radius:6px;
  padding:10px;
  width:50%;
  background-image:linear-gradient(0deg,hsla(0,0%,100%,.5) 82.34%,#edf3ff 100.8%)
}
</style>